import React from "react";
import { BrowserRouter } from "react-router-dom";
import CustomRoutes from "./routes/CustomRoutes";
import "./styles/App.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        {/* <TopNavBar />
        <SideNavBar /> */}
          <CustomRoutes />
      </div>
    </BrowserRouter>
  );
}

export default App;
