import { groupAbsenceDataByWeek } from "./groupAbsenceDataByWeek";
import absenceDataGroup from "./groupAbsenceDataForTimeReport";

async function structureAbsenceForExport(objects) {
  const compiledObjects = [];
  const groupedAbsence = groupAbsenceDataByWeek(objects);
  const restructureData = [];
  const dataArray = Object.values(groupedAbsence);
  for await (const week of dataArray) {
    week.entries.forEach((element) => {
      const getIdCodeCostCenter = getPayTypeIdAndCostCenter(
        element.absence_type,
        week.employment_type
      );
      const payTypeId = getIdCodeCostCenter.payTypeId;
      const costCenter = getIdCodeCostCenter.costCenter;
      const payTypeCode = getIdCodeCostCenter.payTypeCode;
      restructureData.push({
        periodStartDate: element.date.slice(0, 10),
        periodEndDate: element.date.slice(0, 10),
        quantity: element.absence_hours,
        employmentId: week.employment_number,
        payTypeCode: payTypeCode,
        payTypeId: payTypeId,
        // costCenter: costCenter,
      });
    });
  }
  //const summatedAbsenceData = absenceDataGroup(restructureData);
  return restructureData;
}

function getPayTypeIdAndCostCenter(absenceType, employmentType) {

  switch (absenceType) {
    case "ATF":
      if (employmentType === "month")
        return { payTypeId: 406, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 407, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    case "Sjukfrånvaro":
      return { payTypeId: 360, payTypeCode: "A" };

    case "Bet. Semester":
      if (employmentType === "month")
        return { payTypeId: 510, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 525, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    case "Obet. Semester":
      if (employmentType === "month")
        return { payTypeId: 503, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 507, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    case "Tjänsteledighet":
      if (employmentType === "month")
        return { payTypeId: 620, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 627, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    case "Permission":
      if (employmentType === "month")
        return { payTypeId: 650, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 627, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    case "Föräldraledighet":
      if (employmentType === "month")
        return { payTypeId: 629, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 629, payTypeCode: "L" };

    case "VAB":
      if (employmentType === "month")
        return { payTypeId: 666, costCenter: 7210, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 655, payTypeCode: "L" };

    case "Uttag av komp.tid":
      return { payTypeId: 860, payTypeCode: "L" };

    case "Otillåten frånvaro":
      if (employmentType === "month")
        return { payTypeId: 620, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 627, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    default:
      throw new Error(`Invalid absence type: ${absenceType}`);
  }
}

export default structureAbsenceForExport;
