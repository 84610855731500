import { getWeekDates } from "./getWeekDates";
import { newMonthDuringWeek, otherMonthOnRightOrLeftSide, calculateWorkHoursBetweenTwoDates, getNumberOfMandatoryWorkHoursBetweenTwoDates } from "./monthUtils";
import { findCostCenter } from "./payTypeIdIndex";

/**
 * We need to handle the unreported compensation hours. These are hours which is not reported as compensation hours but the employee has worked more than 40 hours in a week.
 * The hard part in this function is to determine if the unreported hours is in the same month as the week or if it is in another month, which is handled in the else statement.
 */
const handleUnreportedCompensationHours = (week, restructureData, freeDayHours, startDate, endDate, numberOfHoursToRegisterAsNonRegistredComp, ATFHours) => {
    if (week.employment_number == 246) {
      var gg = "Get me here for debuging purpose please"
    }
    var numberOfHoursToRegisterAsNonRegistredComp = 0;
    // TODO: Do we need to add the atf hours here maybe? As it is cosnidered as work hours?
    if (freeDayHours + ATFHours + week.total_work_hours > 40) {

      // TODO: Avrunda till ett normalt belopp
        var nonRegistredCompHours = freeDayHours + week.total_work_hours + week.total_weekend_hours + ATFHours - week.total_comp_hours - week.total_holiday_hours - 40;
        if (nonRegistredCompHours < 0) {
          nonRegistredCompHours = 0;
        }
        //var nonRegistredCompHours = Math.abs(freeDayHours + week.total_work_hours + week.total_weekend_hours - week.total_holiday_hours - 40);

        // Does the week fall within a single mont or is it split between two months?
        if (!newMonthDuringWeek(week, startDate, endDate)) {

          if (nonRegistredCompHours > 0) {
            numberOfHoursToRegisterAsNonRegistredComp = nonRegistredCompHours;
            var nonRegistredCompHoursDates = getWeekDates(week.start_date);
            const payTypeId = 200; // Eller 850 ifall komp, vi sätter lön nu
            restructureData.push({
              periodStartDate: nonRegistredCompHoursDates.first,
              periodEndDate: nonRegistredCompHoursDates.last,
              quantity: nonRegistredCompHours,
              employmentId: week.employment_number,
              payTypeCode: "L",
              payTypeId: payTypeId,
              costCenter: findCostCenter(payTypeId, week.employment_type),
            });
          }
        } else {  
          var numberOfMandatoryHoursLeft = 0;        
          var numberOfHoursWorkedThisMonth = 0;
          if (otherMonthOnRightOrLeftSide(week, startDate, endDate) == "left") {
            // We need to determine how many mandatory hours is left of the broken week.
            numberOfMandatoryHoursLeft = getNumberOfMandatoryWorkHoursBetweenTwoDates(startDate.toISOString().slice(0,10), week.end_date);
            
            // Calculate hours worked after start date
            numberOfHoursWorkedThisMonth = calculateWorkHoursBetweenTwoDates(week.entries, startDate.toISOString().slice(0,10), week.end_date);
          } else {
            // We need to determine how many mandatory hours is left of the broken week.
            numberOfMandatoryHoursLeft = getNumberOfMandatoryWorkHoursBetweenTwoDates(week.start_date, endDate.toISOString().slice(0,10));
            // Calculate hours worked before end date
            numberOfHoursWorkedThisMonth =  calculateWorkHoursBetweenTwoDates(week.entries, week.start_date, endDate.toISOString().slice(0,10));
          }
          
          if(numberOfHoursWorkedThisMonth > numberOfMandatoryHoursLeft) {
            numberOfHoursToRegisterAsNonRegistredComp = nonRegistredCompHours;
          } else {
            numberOfHoursToRegisterAsNonRegistredComp = 0;
          }
          if (numberOfHoursToRegisterAsNonRegistredComp > 0) {
            var nonRegistredCompHoursDates = getWeekDates(week.start_date);
            const payTypeId = 200; // Eller 850 ifall det ska vara komp, lön nu
            restructureData.push({
              periodStartDate: nonRegistredCompHoursDates.first,
              periodEndDate: nonRegistredCompHoursDates.last,
              quantity: numberOfHoursToRegisterAsNonRegistredComp,
              employmentId: week.employment_number,
              payTypeCode: "L",
              payTypeId: payTypeId,
              costCenter: findCostCenter(payTypeId, week.employment_type),
            });
          }
          
            
        }
  
      }
      return [restructureData, numberOfHoursToRegisterAsNonRegistredComp];
}

export default handleUnreportedCompensationHours;