import handleCheckChange from "../utils/certifyUtils/handleCheckChange";

export default function certifiedCheckbox({ element, setCertifyArray, checkedState, setCheckedState, handleRender }) {

  return (
    <input
      className="certifiedCheckbox"
      type="checkbox"
      id={element.id}
      checked={checkedState[element.id] || false} // set the checked state based on the checkedState
      certfied={element.certified}
      onChange={(e) => {
        handleCheckChange(element, e.target.checked, setCertifyArray);
        setCheckedState(prevState => ({ ...prevState, [element.id]: e.target.checked }));
        handleRender();
      }}
    ></input>
  );
}
