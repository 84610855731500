
const checkIfUserIsEligableForFreeDay = async (days, employment_number) => {
    try {
        const response = await fetch('https://jiab.tidig.nu/wp-json/tidig/v1/check_free_days', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
            days: days,
            employment_number: employment_number
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}

export default checkIfUserIsEligableForFreeDay;