const hourlyWorkerPayTypeIds = {
  week: 10,
  missingHours: 33,
  overTime30: 201,
  overTime50: 202,
  overTime70: 203,
  overTime100: 204,
  ob30: 310,
  ob50: 311,
  ob70: 312,
  ob100: 313,
  ob200: 314,

  travelExpenses: 800,
  allowance: 845,
  outcall: 135,
};

const monthlyWorkerPayTypeIds = {
  month: 31,
  missingHours: 33,
  overTime30: 201,
  overTime50: 202,
  overTime70: 203,
  overTime100: 204,
  ob30: 310,
  ob50: 311,
  ob70: 312,
  ob100: 313,
  ob200: 314,
};

const hourlyAbsencePayTypeIds = {
  sickLeave: 360,
  ATF: 407,
  leaveOfAbsence: 627,
  parentalLeave: 629,
  VAB: 655,
  komp: 860,
  vacation: 525,
  // unpaid vacation missing
  // permission
  // unauthorized absence
};

const monthlyAbsencePayTypeIds = {
  ATF: 406,
  VAB: 666,
  komp: 860,
  vacation: 510,
  sickLeave: 360,
};

const costCenters = {
  10: 7010,
  31: 7010,
  33: 7010,
  135: 7010,
  200: 7010,
  201: 7010,
  202: 7010,
  203: 7010,
  204: 7010,
  310: 7010,
  311: 7010,
  312: 7010,
  313: 7010,
  510: 7082,
  525: 7082,
  655: 7010,
  666: 7210,
  800: 7332,
  845: 7321,
  851: 7010,
  852: 7010,
  852: 7010,
  853: 7010,
};

const findValueByKey = (obj, key) => {
  return obj[key];
};

export const findCostCenter = (payTypeId, employment_type) => {
  const costCenter = findValueByKey(costCenters, Number(payTypeId));
  return costCenter;
};

export const findPayTypeId = (payTypeCode, employment_type) => {
  let payTypeId = null;
  if (employment_type === "hour") {
    payTypeId = hourlyWorkerPayTypeIds[payTypeCode];
  } else if (employment_type === "month") {
    payTypeId = monthlyWorkerPayTypeIds[payTypeCode];
  }
  return payTypeId;
};
