import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { patchTimeReport } from "../utils/handleReportsUtils/patchTimeReport";
import { patchAbsenceReport } from "../utils/handleReportsUtils/patchAbsenceReport";
import "../styles/EditableInput.css";


const DropDownInput = ({ editFields, isAbsenceReport = false, value, type, id, handleRender, getOptionsFunction }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const loadOptions = async () => {
      const options = await getOptionsFunction();
      setOptions(options);
    };

    loadOptions();
  }, []);
  useEffect(() => {
    if (value) {
      setSelectedOption({ label: value, value });
    }
  }, [value]);

  const handleChange = async (option) => {
    setSelectedOption(option);

    if (option === null) {
      if (isAbsenceReport) {
        await patchAbsenceReport(id, type, "");
      } else {
        await patchTimeReport(id, type, ""); 
      }

    } else {
      if (isAbsenceReport) {
        await patchAbsenceReport(id, type, option.value);
      } else {
        await patchTimeReport(id, type, option.value); 
      }
    }
    handleRender();
  };

  const handleCreate = async (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setOptions([...options, newOption]);
    setSelectedOption(newOption);
    await patchTimeReport(id, type, inputValue);
    handleRender();
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: "100%",
      minHeight: "100%",
      border: "none",
      textAlign: "left",
      fontSize: "13px",
      backgroundColor: "rgba(255, 122, 89, 0)",
      width: "250px",
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: "0 5px",
    }),
    input: (base, state) => ({
      ...base,
      margin: 0,
      padding: 0,
      width: 70,

    }),
  };
  const formatCreateLabel = (inputValue) => `Lägg till ny ort "${inputValue}"`;
  const noOptionsMessage = () => "Inga orter tillgängliga";

  return (
    <CreatableSelect
      placeholder="" // Placeholder for when no option is selected
      formatCreateLabel={formatCreateLabel} // Placeholder for creating a new option
      noOptionsMessage={noOptionsMessage}
      className="editableInput"
      value={selectedOption}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={options}
      isDisabled={editFields}
      styles={customStyles}
      isClearable
      isSearchable
    />
  );
};

export default DropDownInput;
