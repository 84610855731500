import { fetchWithTimeout } from "../fetchWithTimeout";

async function updateEditable(editable, id) {
  const customerURL = localStorage.getItem("customerUrl");
  const userToken = localStorage.getItem("token");
  var url = customerURL + "/wp-json/tidig/v1/updateEditable";
  const data = await fetchWithTimeout(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      id: id,
      editable: editable,
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson.req;
    })
    .catch((error) => {
      console.error(error, "this is the error");
    });
  return await data;
}

export { updateEditable };
