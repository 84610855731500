import { findCostCenter } from "./payTypeIdIndex";

// Helper function to calculate comp time for a day
const calculateCompTime = (day) => {
  return (day.comp_30 || 0) + (day.comp_50 || 0) + (day.comp_70 || 0) + (day.comp_100 || 0);
};

// Helper function to create comp objects based on compensation type and hours worked
/**
 * Explanation of Parameters:
      * compensationType: This determines whether the person is paid a salary (rather than being hourly).
      * 
      * thresholdId: This is the pay type ID that gets applied when certain conditions are met — 
      * specifically, when the employee's total worked hours exceed a defined threshold (e.g., 40 hours). If the employee is salaried and works more than 40 hours, 
      * different overtime rules apply, and this pay type ID will be used.
      * 
      * defaultId: This is the fallback pay type ID that applies if the employee's worked hours don't meet the overtime threshold or if the employee is not salaried.
 */
const createCompObject = (day, realTotalWorkedHours) => {
  const payTypeIdForComp = (compensationType, thresholdId, defaultId) =>
    compensationType === "Salary" ? thresholdId : defaultId;

  return [
    {
      quantity: day.comp_30 || 0,
      payTypeId: payTypeIdForComp(day.compensation_type, realTotalWorkedHours >= 40 ? 201 : 313, 851),
    },
    {
      quantity: day.comp_50 || 0,
      payTypeId: payTypeIdForComp(day.compensation_type, realTotalWorkedHours >= 40 ? 202 : 313, 852),
    },
    {
      quantity: day.comp_70 || 0,
      payTypeId: payTypeIdForComp(day.compensation_type, realTotalWorkedHours >= 40 ? 203 : 313, 853),
    },
    {
      quantity: day.comp_100 || 0,
      payTypeId: payTypeIdForComp(day.compensation_type, realTotalWorkedHours >= 40 ? 204 : 313, 854),
    },
  ];
};

// Helper function to add compensation objects to restructureData
const addCompObjects = (compObjects, restructureData, compObjectTemplate, employment_number, day) => {
  compObjects.forEach((comp) => {
    if (comp.quantity > 0) {
      const compObject = { ...compObjectTemplate, ...comp };
      restructureData.push(compObject);

      // If compensation type is Salary, add additional restructure data entry
      if (day.compensation_type === "Salary") {
        restructureData.push({
          payTypeCode: "L",
          periodStartDate: day.date,
          periodEndDate: day.date,
          quantity: comp.quantity,
          employmentId: employment_number,
          payTypeId: 200,
        });
      }
    }
  });
};

// Main function to handle weekends and holidays
const handleWeekendsAndHolidays = (
  days,
  payTypeId,
  employmentType,
  restructureData,
  employment_number,
  week
) => {
  const realTotalWorkedHours = week ? week.total_reported_hours : 0;
  let totalHours = 0;

  days.forEach((day) => {
    const compTime = calculateCompTime(day);

    const compObjectTemplate = {
      periodStartDate: day.date,
      periodEndDate: day.date,
      employmentId: employment_number,
      payTypeCode: "L",
      costCenter: findCostCenter(payTypeId, employmentType),
    };

    const compObjects = createCompObject(day, realTotalWorkedHours);
    addCompObjects(compObjects, restructureData, compObjectTemplate, employment_number, day);

    const hoursWithoutOvertime = Math.max(0, day.work_hours - compTime);
    if (hoursWithoutOvertime > 0) {
      restructureData.push({
        payTypeCode: "L",
        periodStartDate: day.date,
        periodEndDate: day.date,
        quantity: hoursWithoutOvertime,
        employmentId: employment_number,
        payTypeId: 200,
      });
    }

    totalHours += hoursWithoutOvertime + compTime;
  });

  return totalHours;
};

export default handleWeekendsAndHolidays;




/**
 * 
 * import { findCostCenter } from "./payTypeIdIndex";

const handleWeekendsAndHolidays = (
  days,
  payTypeId,
  employmentType,
  restructureData,
  employment_number,
  week
) => {
  let realTotalWorkedHours = 0;
  if (week) {
    realTotalWorkedHours = week.total_reported_hours;
  }
  let totalHours = 0;
  let compTime = 0;
  days.forEach((day) => {
    compTime = 0;
    compTime +=
      (day.comp_30 || 0) +
      (day.comp_50 || 0) +
      (day.comp_70 || 0) +
      (day.comp_100 || 0);
    const compObjectTemplate = {
      periodStartDate: day.date,
      periodEndDate: day.date,
      employmentId: employment_number,
      payTypeCode: "L",
      costCenter: findCostCenter(payTypeId, employmentType),
    };
    const compObjects = [
      {
        quantity: day.comp_30 || 0,
        payTypeId:
          day.compensation_type === "Salary"
            ? realTotalWorkedHours >= 40
              ? 201
              : 313
            : realTotalWorkedHours >= 40
            ? 851
            : 851,
      },
      {
        quantity: day.comp_50 || 0,
        payTypeId:
          day.compensation_type === "Salary"
            ? realTotalWorkedHours >= 40
              ? 202
              : 313
            : realTotalWorkedHours >= 40
            ? 852
            : 852,
      },
      {
        quantity: day.comp_70 || 0,
        payTypeId:
          day.compensation_type === "Salary"
            ? realTotalWorkedHours >= 40
              ? 203
              : 313
            : realTotalWorkedHours >= 40
            ? 853
            : 853,
      },
      {
        quantity: day.comp_100 || 0,
        payTypeId:
          day.compensation_type === "Salary"
            ? realTotalWorkedHours >= 40
              ? 204
              : 313
            : realTotalWorkedHours >= 40
            ? 854
            : 854,
      },
    ];

    compObjects.forEach((comp) => {
      if (comp.quantity > 0) {
        const compObject = { ...compObjectTemplate, ...comp };
        restructureData.push(compObject);
        totalHours += comp.quantity;
        if (
          day.compensation_type === "Salary"
        ) {
          restructureData.push({
            payTypeCode: "L",
            periodStartDate: day.date,
            periodEndDate: day.date,
            quantity: comp.quantity,
            employmentId: employment_number,
            payTypeId: 200,
          });
        }
      }
    });
    let hoursWithoutOvertimeAddition = 0;
    if (compTime < day.work_hours) {
      hoursWithoutOvertimeAddition = day.work_hours - compTime;
      restructureData.push({
        payTypeCode: "L",
        periodStartDate: day.date,
        periodEndDate: day.date,
        quantity: hoursWithoutOvertimeAddition,
        employmentId: employment_number,
        payTypeId: 200,
      });
    }
    totalHours += day.work_hours - compTime; 
  });
  return totalHours;
};

export default handleWeekendsAndHolidays;
 */
