const handleCheckChange = (element, checked, setCertifyArray) => {
  const value = checked ? 1 : 0;
  setCertifyArray((prevArray) => {
    const newArray = [...prevArray];
    const foundIndex = newArray.findIndex((item) => item.id === element.id);
    if (foundIndex !== -1) {
      if (value === 0) {
        newArray.splice(foundIndex, 1);
      } else {
        newArray[foundIndex].certified = parseInt(element.certified);
      }
    } else if (value === 1) {
      newArray.push({
        id: element.id,
        certified: parseInt(element.certified),
      });
    }
    return newArray;
  });
};

export default handleCheckChange;
