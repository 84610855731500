const getCustomerLogo = async (username) => {
  const endpoint = "https://admin.tidig.nu/wp-json/tidig/v1/get_logo";
  let adress = "";
  const imgUrl = fetch(endpoint, {
    method: "POST",
    body: JSON.stringify({
      url: "http://yourcustomerurl.com",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return await imgUrl;
};

export default getCustomerLogo;
