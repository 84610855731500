

const isDateAHoliday = (date) => {
    return publicHolidays.includes(date);
}

export default isDateAHoliday;

const publicHolidays = [
    '2023-01-01',
    '2023-01-06',
    '2023-04-07',
    '2023-04-09',
    '2023-04-10',
    '2023-05-01',
    '2023-05-18',
    '2023-05-28',
    '2023-06-06',
    '2023-06-23',
    '2023-06-24',
    '2023-11-04',
    '2023-12-24',
    '2023-12-25',
    '2023-12-26',
    '2023-12-31',
    '2024-01-01',
    '2024-01-06',
    '2024-03-29',
    '2024-03-31',
    '2024-04-01',
    '2024-05-01',
    '2024-05-09',
    '2024-05-19',
    '2024-06-06',
    '2024-06-21',
    '2024-06-22',
    '2024-11-02',
    '2024-12-24',
    '2024-12-25',
    '2024-12-26',
    '2024-12-31',
    '2025-01-01',
    '2025-01-06',
    '2025-04-18',
    '2025-04-20',
    '2025-04-21',
    '2025-05-01',
    '2025-05-29',
    '2025-06-06',
    '2025-06-08',
    '2025-06-20',
    '2025-06-21',
    '2025-11-01',
    '2025-12-24',
    '2025-12-25',
    '2025-12-26',
    '2025-12-31',
    '2026-01-01',
    '2026-01-06',
    '2026-04-03',
    '2026-04-05',
    '2026-04-06',
    '2026-05-01',
    '2026-05-14',
    '2026-05-24',
    '2026-06-06',
    '2026-06-19',
    '2026-06-20',
    '2026-10-31',
    '2026-12-24',
    '2026-12-25',
    '2026-12-26',
    '2026-12-31',
    '2027-01-01',
    '2027-01-06',
    '2027-03-26',
    '2027-03-28',
    '2027-03-29',
    '2027-05-01',
    '2027-05-06',
    '2027-05-16',
    '2027-06-06',
    '2027-06-25',
    '2027-06-26',
    '2027-11-06',
    '2027-12-24',
    '2027-12-25',
    '2027-12-26',
    '2027-12-31',
    '2028-01-01',
    '2028-01-06',
    '2028-04-14',
    '2028-04-16',
    '2028-04-17',
    '2028-05-01',
    '2028-05-25',
    '2028-06-04',
    '2028-06-06',
    '2028-06-23',
    '2028-06-24',
    '2028-11-04',
    '2028-12-24',
    '2028-12-25',
    '2028-12-26',
    '2028-12-31',
    '2029-01-01',
    '2029-01-06',
    '2029-03-30',
    '2029-04-01',
    '2029-04-02',
    '2029-05-01',
    '2029-05-10',
    '2029-05-20',
    '2029-06-06',
    '2029-06-22',
    '2029-06-23',
    '2029-11-03',
    '2029-12-24',
    '2029-12-25',
    '2029-12-26',
    '2029-12-31',
    '2030-01-01',
    '2030-01-06',
    '2030-04-19',
    '2030-04-21',
    '2030-04-22',
    '2030-05-01',
    '2030-05-30',
    '2030-06-06',
    '2030-06-09',
    '2030-06-21',
    '2030-06-22',
    '2030-11-02',
    '2030-12-24',
    '2030-12-25',
    '2030-12-26',
    '2030-12-31',
    '2031-01-01',
    '2031-01-06',
    '2031-04-11',
    '2031-04-13',
    '2031-04-14',
    '2031-05-01',
    '2031-05-22',
    '2031-06-01',
    '2031-06-06',
    '2031-06-20',
    '2031-06-21',
    '2031-11-01',
    '2031-12-24',
    '2031-12-25',
    '2031-12-26',
    '2031-12-31',
    '2032-01-01',
    '2032-01-06',
    '2032-03-26',
    '2032-03-28',
    '2032-03-29',
    '2032-05-01',
    '2032-05-06',
    '2032-05-16',
    '2032-06-06',
    '2032-06-25',
    '2032-06-26',
    '2032-11-06',
    '2032-12-24',
    '2032-12-25',
    '2032-12-26',
    '2032-12-31',
    '2033-01-01',
    '2033-01-06',
    '2033-04-15',
    '2033-04-17',
    '2033-04-18',
    '2033-05-01',
    '2033-05-26',
    '2033-06-05',
    '2033-06-06',
    '2033-06-24',
    '2033-06-25',
    '2033-11-05',
    '2033-12-24',
    '2033-12-25',
    '2033-12-26',
    '2033-12-31',
    '2034-01-01',
    '2034-01-06',
    '2034-04-07',
    '2034-04-09',
    '2034-04-10',
    '2034-05-01',
    '2034-05-18',
    '2034-05-28',
    '2034-06-06',
    '2034-06-23',
    '2034-06-24',
    '2034-11-04',
    '2034-12-24',
    '2034-12-25',
    '2034-12-26',
    '2034-12-31',
    '2035-01-01',
    '2035-01-06',
    '2035-03-23',
    '2035-03-25',
    '2035-03-26',
    '2035-05-01',
    '2035-05-03',
    '2035-05-13',
    '2035-06-06',
    '2035-06-22',
    '2035-06-23',
    '2035-11-03',
    '2035-12-24',
    '2035-12-25',
    '2035-12-26',
    '2035-12-31',
    '2036-01-01',
    '2036-01-06',
    '2036-04-11',
    '2036-04-13',
    '2036-04-14',
    '2036-05-01',
    '2036-05-22',
    '2036-06-01',
    '2036-06-06',
    '2036-06-20',
    '2036-06-21',
    '2036-11-01',
    '2036-12-24',
    '2036-12-25',
    '2036-12-26',
    '2036-12-31',
    '2037-01-01',
    '2037-01-06',
    '2037-04-03',
    '2037-04-05',
    '2037-04-06',
    '2037-05-01',
    '2037-05-14',
    '2037-05-24',
    '2037-06-06',
    '2037-06-19',
    '2037-06-20',
    '2037-10-31',
    '2037-12-24',
    '2037-12-25',
    '2037-12-26',
    '2037-12-31',
    '2038-01-01',
    '2038-01-06',
    '2038-04-23',
    '2038-04-25',
    '2038-04-26',
    '2038-05-01',
    '2038-06-03',
    '2038-06-06',
    '2038-06-13',
    '2038-06-25',
    '2038-06-26',
    '2038-11-06',
    '2038-12-24',
    '2038-12-25',
    '2038-12-26',
    '2038-12-31',
    '2039-01-01',
    '2039-01-06',
    '2039-04-08',
    '2039-04-10',
    '2039-04-11',
    '2039-05-01',
    '2039-05-19',
    '2039-05-29',
    '2039-06-06',
    '2039-06-24',
    '2039-06-25',
    '2039-11-05',
    '2039-12-24',
    '2039-12-25',
    '2039-12-26',
    '2039-12-31',
    '2040-01-01',
    '2040-01-06',
    '2040-03-30',
    '2040-04-01',
    '2040-04-02',
    '2040-05-01',
    '2040-05-10',
    '2040-05-20',
    '2040-06-06',
    '2040-06-22',
    '2040-06-23',
    '2040-11-03',
    '2040-12-24',
    '2040-12-25',
    '2040-12-26',
    '2040-12-31'
    ];
    