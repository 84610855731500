import { findCostCenter } from "./payTypeIdIndex";
import { findPayTypeId } from "./payTypeIdIndex";

const timeToNumber = (time) => {
  const [hours, minutes] = time.split(":");
  return Number(hours);
};

/**
 * This function is imortant to decide wheter the employee has worked overtime and should 
 * be compensated for it with OB or OT. If the employee have been working more than 40 hours 
 * the user should be compensated with OT, OB otherwise.
 */
export const createCompObject2 = async (week, restructureData) => {
  let overTimeWithoutCompensation = 0;
  for (const entry of week.entries) {
    const verifiedTimes = [];

    let startTime = timeToNumber(entry.start_time);
    let stopTime =
      timeToNumber(entry.stop_time) +
      (timeToNumber(entry.stop_time) < startTime ? 24 : 0);

    let ob_night_rules = [
      { start: 16, end: 17, payType: 310 },
      { start: 17, end: 19, payType: 311 },
      { start: 19, end: 22, payType: 312 },
      { start: 22, end: 5 + 24, payType: 313 },
      { start: 5 + 24, end: 6 + 24, payType: 311 },
      { start: 6 + 24, end: 17 + 24, payType: 310 },
    ];
    let OT_rules_night = [
      { start: 16, end: 17, payType: 201 },
      { start: 17, end: 19, payType: 202 },
      { start: 19, end: 22, payType: 203 },
      { start: 22, end: 5 + 24, payType: 204 },
      { start: 5 + 24, end: 6 + 24, payType: 202 },
      { start: 6 + 24, end: 16 + 24, payType: 201 },
    ];
    // day rules
    let OB_rules = [
      { start: 16, end: 17, payType: 310 },
      { start: 17, end: 19, payType: 311 },
      { start: 19, end: 22, payType: 312 },
      { start: 22, end: 5 + 24, payType: 313 },
    ];

    let OT_rules = [
      { start: 16, end: 17, payType: 201 },
      { start: 17, end: 19, payType: 202 },
      { start: 19, end: 22, payType: 203 },
      { start: 22, end: 5 + 24, payType: 204 },
      { start: 5, end: 6, payType: 202 },
    ];
    let OB_hoursCounted = 0;
    let OT_hoursCounted = 0;
    let breakTimer = 0;
    for (let i = startTime; i < stopTime; i++) {
      breakTimer++;
      // OLD LOGIK USED FOR CREATING BREAKS AFTER 5 HOURS. Simply uncomment below to activate
      // if (breakTimer === 5) continue;
      let currentHour = i;
      let rules;

      // Determine which set of rules to use
      if (startTime >= 18 && startTime <= 27) {
        // We're in the night time
        if (OB_hoursCounted < 8 && week.total_reported_hours < 40) {
          // We haven't hit 8 hours of OB yet
          rules = ob_night_rules;
        } else {
          // We've hit 8 hours of OB, so switch to OT
          rules = OT_rules_night;
        }
      } else {
        // We're not in the night time
        if (week.total_reported_hours < 40) {
          // We haven't hit 40 hours of work yet, so use OB rules
          rules = OB_rules;
        } else {
          // We've hit 40 hours of work, so switch to OT rules
          rules = OT_rules;
        }
      }
      for (const rule of rules) {
        if (currentHour >= rule.start && currentHour < rule.end) {
          let payTypeId = rule.payType;
          let quantity = 1;

          verifiedTimes.push({
            date: entry.date,
            hour: currentHour,
            payTypeId: rule.payType,
          });
          // Increment the appropriate hours counter
          if (rules === ob_night_rules || rules === OB_rules) {
            OB_hoursCounted++;
          } else {
            OT_hoursCounted++;
          }

          break;
        }
      }
    }
    const hoursWorkedAfter = {
      16: 0,
      17: 0,
      19: 0,
      22: 0,
      total: 0,
    };

    // Count hours worked after specific times
    verifiedTimes.forEach((time) => {
      if (time.hour >= 16) {
        hoursWorkedAfter["16"]++;
        hoursWorkedAfter["total"]++;
      }
      if (time.hour >= 17) {
        hoursWorkedAfter["17"]++;
        hoursWorkedAfter["total"]++;
      }
      if (time.hour >= 19) {
        hoursWorkedAfter["19"]++;
        hoursWorkedAfter["total"]++;
      }
      if (time.hour >= 22 || time.hour < 5) {
        hoursWorkedAfter["22"]++;
        hoursWorkedAfter["total"]++;
      }
    });
    // TODO: Check if entry.comp_30 really shoud be positive here?
    const compTotal = entry.comp_30 - entry.comp_50 - entry.comp_70 - entry.comp_100;
    if (compTotal < entry.work_hours && startTime > 16) {
      const total = entry.work_hours - compTotal;
      overTimeWithoutCompensation = +total;
      restructureData.push({
        periodStartDate: entry.date,
        periodEndDate: entry.date,
        quantity: total,
        employmentId: week.employment_number,
        payTypeCode: "L",
        payTypeId: 200,
        costCenter: findCostCenter(200, week.employment_type),
      });
    }
    if (entry.compensation_type === "Salary") {
      checkAndCreatePayObjects(
        entry,
        week,
        restructureData,
        "comp_30",
        201,
        16
      );
      checkAndCreatePayObjects(
        entry,
        week,
        restructureData,
        "comp_50",
        202,
        17
      );
      checkAndCreatePayObjects(
        entry,
        week,
        restructureData,
        "comp_70",
        203,
        19
      );
      checkAndCreatePayObjects(
        entry,
        week,
        restructureData,
        "comp_100",
        204,
        22
      );
    } else {
      checkAndCreatePayObjects(
        entry,
        week,
        restructureData,
        "comp_30",
        851,
        16
      );
      checkAndCreatePayObjects(
        entry,
        week,
        restructureData,
        "comp_50",
        852,
        17
      );
      checkAndCreatePayObjects(
        entry,
        week,
        restructureData,
        "comp_70",
        853,
        19
      );
      checkAndCreatePayObjects(
        entry,
        week,
        restructureData,
        "comp_100",
        854,
        22
      );
    }
  }

  return overTimeWithoutCompensation;
};

function checkAndCreatePayObjects(
  entry,
  week,
  restructureData,
  compLevel,
  payTypeId,
) {
  if (entry[compLevel]) {
    restructureData.push({
      periodStartDate: entry.date,
      periodEndDate: entry.date,
      quantity: entry[compLevel],
      employmentId: week.employment_number,
      payTypeCode: "L",
      payTypeId: payTypeId,
      costCenter: findCostCenter(payTypeId, week.employment_type),
    });
    // Shadow object creation
    if (
      entry.compensation_type === "Salary" 
    ) {
      restructureData.push({
        periodStartDate: entry.date,
        periodEndDate: entry.date,
        quantity: entry[compLevel],
        employmentId: week.employment_number,
        payTypeCode: "L",
        payTypeId: 200,
        costCenter: findCostCenter(200, week.employment_type),
      });
    }
  }
}
