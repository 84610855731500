import { groupDataByWeek } from "./groupTimeReportDataByWeek";
import checkForRegisteredAbsence from "./checkForRegisteredAbsence";
import checkForHoliday from "./checkForHoliday";
import groupTimeReportDataByMonth from "./groupTimeReportDataByMonth";
import convertToXmlStructure from "./convertToXmlStructure";
import groupAbsenceDataForTimeReport from "./groupAbsenceDataForTimeReport";
import getPeriodWeeks from "./getPeriodWeeks";
import handleWeekendsAndHolidays from "./handleWeekendsAndHolidays";
import createRegularPayObject from "./CreateRegularPayObject";
import handleReimbursements from "./handleReimbursements";
import createFreeHolidayObject from "./createFreeHolidayObject";
import { createCompObject2 } from "./createCompObject2";
import removeAbsenceDataOutsidePeriod from "./removeAbsenceDataOutsidePeriod";
import calculateTotalWorkHoursForMonthEmployees from "./calculateTotalWorkHoursForMonthEmployees";
import handleMissingHours from "./handleMissingHours";
import handleUnreportedCompensationHours from "./handleUnreportedCompensationHours";
import extractATFAbsenceHours from "./extractATFAbsenceHours";
import isASingleUserSelectedForExport from "./isASingleUserSelectedForExport";
import getMonthEmploymentUsersFromUserList from "./getMonthEmploymentUsersFromUserList";
import createEmptyWeekDataForMonthEmploymentUsers from "./createEmptyWeekDataForMonthEmploymentUsers";
import handleSickLeaveAndVABHours from "./handleSickLeaveAndVABHours";

/**
 * Process the data to structure it for export
 * @param {Array} data - The primary data to be structured.
 * @param {Array} entireWeekData - Data related to the entire week.
 * @param {Date} startDate - Start date of the period.
 * @param {Date} endDate - End date of the period.
 * @returns {Array} - The structured data ready for export.
 */
async function structureDataForExport(
  data,
  entireWeekData,
  startDate,
  endDate,
  usersList
) {
  // If there is no specific user selected for the report. We need to add all of the 
  // monthly users which have not reported any data, otherwise these users will not be included in the export.
  if (!isASingleUserSelectedForExport(data)) {
      var monthEmploymentUsers = usersList;//getMonthEmploymentUsersFromUserList(usersList);
      //Remove any employees that already have reported data
      monthEmploymentUsers = monthEmploymentUsers.filter((user) => {
          return !data.some((week) => week.employment_number === user.employee_number);
      });
      
      var emptyWeekDataForMonthEmploymentUsers = createEmptyWeekDataForMonthEmploymentUsers(monthEmploymentUsers, startDate, endDate);
      
      data = data.concat(emptyWeekDataForMonthEmploymentUsers);
      
  }

  // Get all the holidays for a specific year and country code "se"
  const holidays = await checkForHoliday("se", data[0].date.slice(0, 4));
  
  // Extract dates from the holidays array
  const holidayDates = holidays.map((holiday) => holiday.date);

  // Filter holidays by specific names
  const freeDays = holidays.filter((holiday) =>
    [
      "Epiphany",
      "Good Friday",
      "Easter Monday",
      "may 1st",
      "Feast of the Ascension of Jesus Christ",
      "Pentecost",
      "All Saints day",
      "Christmas Day",
      "Boxing Day",
      "New Year's Day",
      "Christmas Eve",
      "New Year's Eve",
      "Easter",
      "Midsummer Eve",
      "National Day",
    ].includes(holiday.name)
  );

  // Group data by week while considering holidays and entire week data
  const groupedData = groupDataByWeek(
    data,
    holidayDates,
    entireWeekData,
    startDate,
    endDate
  );
  const restructureData = [];
  // Get the week periods
  const weekPeriod = getPeriodWeeks(groupedData[0].week, startDate);

  // Extract unique user IDs
  const uniqueUserIds = new Set();
  groupedData.forEach((item) => {
    uniqueUserIds.add(item.user_id);
  });
  const uniqueUserIdsArray = Array.from(uniqueUserIds);

  // Initialize absence arrays
  let absenceArray = [];
  let matchingAbsence = [];
  let checkAbsenceType = "rapports";
  // Populate the absence array by iterating through week periods and user IDs
  // TODO: Dublett av kod?
  /*for (const week of weekPeriod) {
    for (const userId of uniqueUserIdsArray) {
      const weekObject = {
        week: week,
        user_id: userId,
        year: groupedData[0].year,
      };

      checkAbsenceType = "hours";
      const absence = await checkForRegisteredAbsence(
        weekObject,
        checkAbsenceType,
        startDate,
        endDate
      );
      if (absence) {
        absenceArray.push(absence);
      }
    }
  }*/


  // Iterate through the grouped data
  for await (const week of groupedData) {
    var ATFHours = 0;
    // Initialize overtime hours
    let overTimeWithoutCompensation = 0;
    // Calculate total reported hours
    let totalAbsenceHours = 0;
    let totalReportedHours = week.total_work_hours + week.total_work_hours_outside_span;
    if (week.employment_number == 83) {
      var gg = "Get me here for debuging purpose please"
    }
    // Check for registered absences in the week
    checkAbsenceType = "hours";
    const checkAbsence = await checkForRegisteredAbsence(week, checkAbsenceType);

    if (checkAbsence) {
      absenceArray.push(checkAbsence);
    }
    // Matching absences
    var findMatchingAbsence = absenceArray.find(
      (absence) =>
        parseInt(absence.week) === parseInt(week.week) &&
        parseInt(absence.user_id) === parseInt(week.user_id)
    );

    if (checkAbsence && checkAbsence.entries.length > 0) {
      ATFHours = extractATFAbsenceHours(checkAbsence, week);
    }

    // If a matching absence is found, add to the matchingAbsence array
    if (findMatchingAbsence) {
      // If there is days where the employee is sick, or VABar, the user has reported the expected hours vab or illness for the day. However, when JIAB is calculating the hours for the week,
      // the hours of illness or VAB should be calculated as a full day if it is the only hours that has been reported for that day. This is because the hours should be right on the lön
      // specification.
      var updatedAbsence = handleSickLeaveAndVABHours(week, findMatchingAbsence);
      matchingAbsence.push(updatedAbsence);
     
    }
    // If absence is found, add its hours to the total reported hours
    if (checkAbsence.year > 0) {
      totalAbsenceHours += checkAbsence.total_absence_hours;
      totalReportedHours += checkAbsence.total_absence_hours;
    }

    // If the absence is of type ATF it should be counted as regular hours for the week. These are needed to calculate the unregistred compensation hours.
    
    

    // Create a free day object and adjust total reported hours if a free day is found
    var freeDayHours = await createFreeHolidayObject(week, freeDays, restructureData);
    if (freeDayHours > 0) {
      totalAbsenceHours += freeDayHours;
      totalReportedHours += freeDayHours;
    }

    // Update week's total reported hours
    week.total_reported_hours = totalReportedHours;

    // Handle weekends and holidays, and adjust the restructured data accordingly
    var holidayHours = handleWeekendsAndHolidays(week.holidays, 313, week.employment_type, restructureData, week.employment_number, week);
    var weekendHours = handleWeekendsAndHolidays(week.weekEnds, 204, week.employment_type, restructureData, week.employment_number, week);

    // Handle reimbursements for the week, this is: Reseersättning, Traktamente och Utryckning
    handleReimbursements(week, restructureData);

    // If overtime compensation exists, calculate overtime hours
    if ( week.comp_30 > 0 || week.comp_50 > 0 || week.comp_70 > 0 || week.comp_100 > 0) {
      overTimeWithoutCompensation = await createCompObject2(week, restructureData);
    }

    // If the employee is have the employment type "month" we are going to see how many hours that are supposed to be worked during this
    // week, and also remove any absence hours from those hours, to be able to calculate how many hours the monthly
    if (week.employment_number == 83) {
      var gg = "Get me here for debuging purpose please"
    }
    if (week.employment_type == 'month') {
      var availableHoursForWork = 0;
      if (week.start_date < startDate.toISOString().slice(0,10)) {
        availableHoursForWork = calculateTotalWorkHoursForMonthEmployees(startDate.toISOString().slice(0,10), week.end_date);
      }
      else if (week.end_date > endDate.toISOString().slice(0,10)) {
        availableHoursForWork = calculateTotalWorkHoursForMonthEmployees(week.start_date, endDate.toISOString().slice(0,10));
      } else {
        availableHoursForWork = calculateTotalWorkHoursForMonthEmployees(week.start_date, week.end_date);
      }
      
      week.total_work_hours += availableHoursForWork - totalAbsenceHours;
    }

    // If the user has worked more than 40 hours, but havn't reported any overtime hours,
    // add extra hours as compensation hours. No special case if its reported on weekend or holiday
    var numberOfHoursToRegisterAsNonRegistredComp = 0;

    [, numberOfHoursToRegisterAsNonRegistredComp] = handleUnreportedCompensationHours(week, restructureData, freeDayHours, startDate, endDate, numberOfHoursToRegisterAsNonRegistredComp, ATFHours);

    
    // Create a regular pay object based on calculated data
    createRegularPayObject(week, restructureData, overTimeWithoutCompensation, holidayHours, weekendHours, numberOfHoursToRegisterAsNonRegistredComp);

    // If the total reported hours is less than 40, calculate and adjust for missing hours
    handleMissingHours(week, restructureData, freeDays, totalReportedHours);

 

  }


  /**
   * WHEN DATA XML DATA IS PREPARED
   */
  // Group the restructured data by month
  var newData = groupTimeReportDataByMonth(restructureData, startDate, endDate);

  // If matching absences exist, convert and group them, then append to the new data
  if (matchingAbsence) {
    matchingAbsence = removeAbsenceDataOutsidePeriod(startDate, endDate, matchingAbsence);
    matchingAbsence = await convertToXmlStructure(matchingAbsence);
    
    matchingAbsence = groupAbsenceDataForTimeReport(matchingAbsence);
    matchingAbsence.forEach((absence) => {
      newData.push(absence);
    });
  }

  newData.sort((a, b) => {
    if (a.employmentId < b.employmentId) {
      return -1;
    }
    if (a.employmentId > b.employmentId) {
      return 1;
    }
    if (a.periodStartDate < b.periodStartDate) {
      return -1;
    }
    if (a.periodStartDate > b.periodStartDate) {
      return 1;
    }
    if (a.quantity < b.quantity) {
      return -1;
    }
    if (a.quantity > b.quantity) {
      return 1;
    }
    return 0;
  });

  return newData;
}

export default structureDataForExport;
