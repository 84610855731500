function compoundData(data, startDate, endDate) {
  const compoundedData = {};
  
  const parsedStartDate = Date.parse(startDate);
  const parsedEndDate = Date.parse(endDate);

  data.forEach((obj) => {
    const key = `${obj.employmentId}-${obj.payTypeId}-${obj.periodStartDate}-${obj.periodEndDate}`;
    if (obj.payTypeId === 33 || obj.payTypeId === 350) {
      compoundedData[key] = { ...obj };
    } else {
      const keyNoDate = `${obj.employmentId}-${obj.payTypeId}`;
      if (compoundedData[keyNoDate]) {
        compoundedData[keyNoDate].quantity += obj.quantity;
        
        // Update periodStartDate and periodEndDate
        let newPeriodStartDate = new Date(
          Math.min(
            Date.parse(compoundedData[keyNoDate].periodStartDate),
            Date.parse(obj.periodStartDate.replace(/-/g, '/'))
          )
        );

        let newPeriodEndDate = new Date(
          Math.max(
            Date.parse(compoundedData[keyNoDate].periodEndDate),
            Date.parse(obj.periodEndDate.replace(/-/g, '/'))
          )
        );

        // Check against startDate and endDate
        newPeriodStartDate = new Date(Math.max(newPeriodStartDate.getTime(), parsedStartDate));
        newPeriodEndDate = new Date(parsedEndDate);

        compoundedData[keyNoDate].periodStartDate = newPeriodStartDate.toISOString().slice(0, 10);
        compoundedData[keyNoDate].periodEndDate = newPeriodEndDate.toISOString().slice(0, 10);
        
      } else {
        compoundedData[keyNoDate] = { ...obj };
      }
    }
  });

  const sortedData = Object.values(compoundedData).sort((a, b) => {
    if (a.employmentId === b.employmentId) {
      return a.payTypeId - b.payTypeId;
    }
    return a.employmentId - b.employmentId;
  });

  // Final round to adjust periodStartDate and periodEndDate based on startDate and endDate
  sortedData.forEach((obj) => {
    let objStartDate = new Date(Date.parse(obj.periodStartDate));
    let objEndDate = new Date(Date.parse(obj.periodEndDate));

    // Check against startDate and endDate
    objStartDate = new Date(Math.max(objStartDate.getTime(), parsedStartDate));
    objEndDate = new Date(Math.min(objEndDate.getTime(), parsedEndDate));

    obj.periodStartDate = objStartDate.toISOString().slice(0, 10);
    obj.periodEndDate = objEndDate.toISOString().slice(0, 10);
  });

  return sortedData;
}

export default compoundData;
