export function getWeekDates(date) {
  if (typeof date === "string") {
    date = new Date(date);
  }
  
  let day = date.getDay();
  // If day is 0 (Sunday), change it to 7 (representing last day of the week)
  day = day === 0 ? 7 : day;
  // Subtract one from day to make Monday (which was 1) as first day (now 0)
  day = day - 1;
  
  const firstDate = new Date(date.getTime() - day * 24 * 60 * 60 * 1000);
  const lastDate = new Date(firstDate.getTime() + 6 * 24 * 60 * 60 * 1000);
  
  return {
    first: firstDate.toISOString().slice(0, 10),
    last: lastDate.toISOString().slice(0, 10),
  };
}
