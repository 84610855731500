import React from "react";
import LoginForm from "../components/LoginForm";
import "../styles/App.css";
import "../styles/LoginPage.css";

const LoginPage = ({ handleLoggedInUserData, setIsAuthenticated }) => {
  return (
    <div className="container-loginpage">
      <img className="loginpage__image" src="/tidig.png" alt="logo" />
      <div className="container-loginForm">
        <LoginForm
          handleLoggedInUserData={handleLoggedInUserData}
          setIsAuthenticated={setIsAuthenticated}
        />
      </div>
    </div>
  );
};

export default LoginPage;
