import moment from 'moment';
import isDateAHoliday from './holidays';

export const newMonthDuringWeek = (week, startDate, endDate) => {
    const momentWeekStart = moment(week.start_date);
    const momentWeekEnd = moment(week.end_date);
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    if (momentWeekStart.month() !== momentStartDate.month()) {
        return true;
    }
    if (momentWeekEnd.month() !== momentEndDate.month()) {
        return true;
    }

    return false;
};

export const otherMonthOnRightOrLeftSide = (week, startDate, endDate) => {
    const momentWeekStart = moment(week.start_date);
    const momentWeekEnd = moment(week.end_date);
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    if (momentWeekStart.month() !== momentStartDate.month()) {
        return 'left';
    }
    if (momentWeekEnd.month() !== momentEndDate.month()) {
        return 'right';
    }

    return false;
};

export const calculateWorkHoursBetweenTwoDates = (data, startDate, endDate) => {
    const momentStartDate = moment(startDate);
    const formatedStartDate = momentStartDate.format('YYYY-MM-DD');
    const momentEndDate = moment(endDate);
    const formatedEndDate = momentEndDate.format('YYYY-MM-DD');
    
    const filteredData = data.filter(entry => entry.date >= formatedStartDate && entry.date <= formatedEndDate);
    var totalWorkHours = 0;
    totalWorkHours = filteredData.reduce((sum, entry) => sum + parseInt(entry.work_hours), 0);
      
    return totalWorkHours;
      
}

export const getNumberOfMandatoryWorkHoursBetweenTwoDates = (startDate, endDate) => {
    // We need to loop through each date to see if date is a weekend or holiday. If so, they are not mandatory workdays.
    const momentStartDate = moment(startDate);
    const formatedStartDate = momentStartDate.format('YYYY-MM-DD');
    const momentEndDate = moment(endDate);
    const formatedEndDate = momentEndDate.format('YYYY-MM-DD');
    var totalWorkHours = 0;
    var currentDate = moment(formatedStartDate);
    while (currentDate <= momentEndDate) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6 && !isDateAHoliday(currentDate.format('YYYY-MM-DD')) ) {
            totalWorkHours += 8;
        }
        currentDate.add(1, 'days');
    }
     
    return totalWorkHours;
}