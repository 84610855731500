import moment from 'moment';
import 'moment/locale/sv';

moment.locale('sv');

function getPeriodWeeks(week, startDate) {
  // parse date from start date
  const date = moment(startDate);

  const startOfMonth = date.clone().startOf('month');
  const endOfMonth = date.clone().endOf('month');

  const startWeek = startOfMonth.isoWeek();
  let endWeek = endOfMonth.isoWeek();

  // correct for the year wrap-around
  if (startWeek > endWeek) {
    if (endOfMonth.month() === 0) {
      endWeek = startOfMonth.isoWeeksInYear();
    } else {
      endWeek = startWeek + endOfMonth.date() / 7;
    }
  }

  let weeks = [];
  for (let i = startWeek; i <= endWeek; i++) {
    weeks.push(i);
  }
  return weeks;
}

export default getPeriodWeeks;
