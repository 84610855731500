const moment = require('moment');
require('moment/locale/sv');

moment.locale('sv');

export default function getStartAndEndDates(weeks, year) {
    // Get the first week and its start date
    let startOfWeek = moment().year(year).isoWeek(weeks[0]).startOf('isoWeek').format('YYYY-MM-DD');

    // Get the last week and its end date
    let endOfWeek = moment().year(year).isoWeek(weeks[weeks.length - 1]).endOf('isoWeek').format('YYYY-MM-DD');

    return {
        start: startOfWeek,
        end: endOfWeek
    };
}

