const extractATFAbsenceHours = (matchingAbsence, week) => {
    var totalATFAbsenceHours = 0;
    if (week.employment_number == matchingAbsence.employment_number) {
        for (const entry of matchingAbsence.entries) {
            if (entry.absence_type === "ATF" && matchingAbsence.week === week.week) {
                totalATFAbsenceHours += entry.absence_hours;
            }
        }
    }
    return totalATFAbsenceHours;
}

export default extractATFAbsenceHours;