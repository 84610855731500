import { fetchWithTimeout } from "../fetchWithTimeout";

const getAllUserNames = async () => {
  const customerURL = localStorage.getItem("customerUrl");
  const userToken = localStorage.getItem("token");
  var url = customerURL + "/wp-json/tidig/v1/get_tidig_users";

  const data = await fetchWithTimeout(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const responseData = responseJson;
      return responseData.req;
    })
    .catch((error) => {
      console.error(error, "this is the error");
    });
  return await data.data;
};

export { getAllUserNames };
