const getWeekNumber = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  const yearStart = new Date(d.getFullYear(), 0, 1);
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return weekNo;
};

const postReportArray = async (reports, activeTab) => {
  // Prepare reportsArray to match the expected format in WordPress
  const preparedReports = reports.map((report) => {
    return {
      user_id: report.user,
      start_time: report.startTime,
      stop_time: report.endTime,
      date: report.date,
      week: getWeekNumber(report.date),
      year: new Date(report.date).getFullYear(),
      creation_date: new Date().toISOString(),
      last_updated: new Date().toISOString(),
      editable: true,
      certified: false,
      certified_date: null,
      absence_type: report.absenceType,
      notation: "",
      employment_number: report.employmentNumber,
      employment_type: report.employmentType,
      export: false,
      exported: false,
      work_hours: report.workHours,
      break_time: report.breakTime,
      comp_30: report.comp_30,
      comp_50: report.comp_50,
      comp_70: report.comp_70,
      comp_100: report.comp_100,
      has_allowance: report.has_allowance,
      has_travel_expenses: report.has_travel_expenses,
      out_call: report.out_call,
      compensation_type: report.compensationType,
      city: report.location,
    };
  });
  const customerURL = localStorage.getItem("customerUrl");
  const userToken = localStorage.getItem("token");
  let url = customerURL + "/wp-json/tidig/v1/absencereportfromarray";
  let request = JSON.stringify({ absence_reports: preparedReports });
  if (activeTab === "timereport") {
    url = customerURL + "/wp-json/tidig/v1/timereportfromarray";
    request = JSON.stringify({ time_reports: preparedReports });
  }
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
      "Access-Control-Allow-Origin": "*",
    },
    body: request,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export default postReportArray;
