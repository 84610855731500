import { fetchWithTimeout } from "../fetchWithTimeout";

const isEmployeeEligibleForFreeDaySalary = async (date, employment_number) => {
  // format startDate and endDate to YYYY-MM-DD
  const customerURL = localStorage.getItem("customerUrl");
  const userToken = localStorage.getItem("token");
  let request = "";
  var url = customerURL + "/wp-json/tidig/v1/is_user_eligible_for_free_day_salary";

  const data = await fetchWithTimeout(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      day: date,
      employment_number: employment_number,
  })})
    .then((response) => response.json())
    .then((responseJson) => {
      
      let data = responseJson.status;
      return data;
    })
    .catch((error) => {
      console.error(error, "this is the error");
    });
  return await data;
};

export default isEmployeeEligibleForFreeDaySalary;
