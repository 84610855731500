import isDateAHoliday from './holidays';
/**
 * Helper function to calculate total hours for a period, reducing hours for weekends and holidays
 * @param {string} startDate - The start date of the period
 * @param {string} endDate - The end date of the period
 */
const calculateTotalWorkHoursForMonthEmployees = (startDate, endDate) => { 
    // Calculate total hours for the period
    const currentDate = new Date(startDate);
    let totalDays = 0;
    let end = new Date(endDate);
    end.setHours(23,59,59,999);
    
    while (currentDate <= new Date(end)) {
        // Check if the current date is a weekend or holiday
        if (currentDate.getDay() === 0 || currentDate.getDay() === 6 || isDateAHoliday(currentDate.toISOString().slice(0, 10))) {
            // Skip weekends and holidays
        } else {
            // Add hours for weekdays
            totalDays += 1;
        }
        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
    }
    const totalHours = totalDays * 8;
    return totalHours;
}



export default calculateTotalWorkHoursForMonthEmployees;