async function getCustomerUrl(username) {
  const email = username;
  const response = await fetch(
    "https://admin.tidig.nu/wp-json/tidig/v1/get_customer_url_by_email",
    {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data.req.data;
}

export default getCustomerUrl;
