function absenceDataGroup(data) {
  // If the input data is empty, return an empty array immediately
  if (data.length === 0) return [];

  // Sort the data by payTypeId (ascending), then by employmentId, and then by periodStartDate (ascending)
  data.sort((a, b) => 
    a.payTypeId - b.payTypeId ||
    a.employmentId.localeCompare(b.employmentId) ||
    new Date(a.periodStartDate) - new Date(b.periodStartDate)
  );

  // Initialize variables to store the final grouped data and the current group being processed
  let groupedData = [];
  let currentGroup = [{...data[0]}];
  let currentPayTypeId = data[0].payTypeId;
  let currentEmploymentId = data[0].employmentId;

  // Iterate over the sorted data, starting from the second item
  for(let i = 1; i < data.length; i++) {
    // Calculate the day difference between current and next item
    var dayDifference = Math.floor((new Date(data[i].periodStartDate) - new Date(currentGroup[currentGroup.length - 1].periodEndDate)) / (1000 * 60 * 60 * 24));

    // Check if the current record should be merged into the current group
    // Conditions: same payTypeId, same employmentId, and either dates are consecutive (1 day apart) with quantity >= 8,
    // or dates are within 5 days for payTypeId 360 or 655

    var isSamePayType = data[i].payTypeId === currentPayTypeId;
    var isSameEmployment = data[i].employmentId === currentEmploymentId;
    var isSpecialPayType = data[i].payTypeId === 360 || data[i].payTypeId === 655;
    var isPayTypePaidVacation = data[i].payTypeId === 510  || data[i].payTypeId === 525;

    // Determine the "enough quantity" based on the pay type
    var enoughQuantity = isPayTypePaidVacation ? 1 : 8;

    // Specific logic for paid vacation
    if (isSamePayType && isSameEmployment && isPayTypePaidVacation) {
      // Always treat it as part of the same group
      var lastItem = currentGroup[currentGroup.length - 1];
      lastItem.periodEndDate = data[i].periodEndDate;
      lastItem.quantity += data[i].quantity;
    } else {
      var isWithinOneDayWithEnoughQuantity = dayDifference <= 1 && currentGroup[currentGroup.length - 1].quantity >= enoughQuantity;
      var isWithinFiveDaysForSpecialPayType = dayDifference <= 5 && isSpecialPayType;

      if (isSamePayType && isSameEmployment && (isWithinOneDayWithEnoughQuantity || isWithinFiveDaysForSpecialPayType)) {
        // Update the last item's periodEndDate and quantity
        var lastItem = currentGroup[currentGroup.length - 1];
        lastItem.periodEndDate = data[i].periodEndDate;
        lastItem.quantity += data[i].quantity;
      } else {
        if (currentPayTypeId === 360 || currentPayTypeId === 655) {
          // Update periodStartDate of all items in currentGroup with the earliest date
          let earliestDate = currentGroup.reduce((min, item) => 
            item.periodStartDate < min ? item.periodStartDate : min, 
            currentGroup[0].periodStartDate
          );
          currentGroup.forEach(item => item.periodStartDate = earliestDate);
        }
        // Push all items in currentGroup into groupedData
        groupedData.push(...currentGroup);
        // Start a new group with the current item
        currentGroup = [{ ...data[i] }];
        currentPayTypeId = data[i].payTypeId;
        currentEmploymentId = data[i].employmentId;
      }
    }
  }

  // Process the last group
  if(currentPayTypeId === 360 || currentPayTypeId === 655) {
    // Update periodStartDate of all items in currentGroup with the earliest date
    let earliestDate = currentGroup.reduce((min, item) => item.periodStartDate < min ? item.periodStartDate : min, currentGroup[0].periodStartDate);
    currentGroup.forEach(item => item.periodStartDate = earliestDate);
  }
  // Push all items in currentGroup into groupedData
  groupedData.push(...currentGroup);

  return groupedData;
}

export default absenceDataGroup;
