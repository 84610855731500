import React, { useState, useEffect } from "react";
import { updateCertifiedArray } from "../utils/handleReportsUtils/updateCertifiedArray";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";

import "../styles/CertifyButton.css";

function CertifyButton({
  path,
  currentPageData,
  checkedState,
  setCheckedState,
  handleRender,
  certifyArray,
  setCertifyArray,
}) {
  const [allCheckBox, setAllCheckBox] = useState(false);

  const handleCheckboxChange = (isChecked) => {
    const newItems = currentPageData.map((item) => {
      return {
        id: item.id,
        certified: parseInt(item.certified),
      };
    });

    // If isChecked is true, add newItems to checkedState and certifyArray
    if (isChecked) {
      const newCheckedState = newItems.reduce(
        (acc, item) => {
          return {
            ...acc,
            [item.id]: true,
          };
        },
        { ...checkedState }
      );

      const newCertifyArray = [...certifyArray, ...newItems];
      setCertifyArray(newCertifyArray);
      setCheckedState(newCheckedState);
      handleRender();
    }

    // If isChecked is false, remove newItems from checkedState and certifyArray
    else {
      const newCheckedState = { ...checkedState };
      newItems.forEach((item) => {
        delete newCheckedState[item.id];
      });

      const newCertifyArray = certifyArray.filter(
        (certifyItem) =>
          !newItems.some((newItem) => newItem.id === certifyItem.id)
      );

      setCheckedState(newCheckedState);
      setCertifyArray(newCertifyArray);
      handleRender();
    }
  };

  // A useEffect hook to handle the allCheckBox state
  useEffect(() => {
    const checkedItemsCount = currentPageData.filter((item) => checkedState[item.id]).length;
    setAllCheckBox(checkedItemsCount === currentPageData.length);
  }, [currentPageData, checkedState]);

  return (
    <>
      <div className="certifyButtonContainer">
        <input
          className="certifiedCheckbox"
          type="checkbox"
          checked={allCheckBox}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setAllCheckBox(isChecked);
            handleCheckboxChange(isChecked);
          }}
        ></input>

        <button
          className="certifyButton"
          onClick={() => {
            updateCertifiedArray(certifyArray, true, path);
            setTimeout(() => {
              handleRender();
              setCheckedState({});
              setAllCheckBox(false);
              setCertifyArray([]);
            }, 100);
          }}
        >
          {/* Godkänn */}
          <FontAwesomeIcon icon={faCheck} size="sm" />
        </button>
        <button
          className="certifyCancelButton"
          onClick={() => {
            updateCertifiedArray(certifyArray, false, path);
            setCheckedState({});
            setAllCheckBox(false);
            setCertifyArray([]);
            handleRender();
          }}
        >
          {/* Godkänn */}
          <FontAwesomeIcon icon={faX} size="sm" />
        </button>
      </div>
    </>
  );
}
export default CertifyButton;
