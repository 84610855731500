import { groupAbsenceDataByWeek } from "./groupAbsenceDataByWeek";
const checkForRegisteredAbsence = async (week, checkAbsenceType, startDate, endDate) => {
  const url = localStorage.getItem("customerUrl");
  const completeUrl = url + "/wp-json/tidig/v1/absencereport?user_id=" + week.user_id + "&week=" + week.week + "&year=" + week.year;
  const response = await fetch(completeUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }



  const data = await response.json();
  const groupedAbsenceData = groupAbsenceDataByWeek(data.req.data);
  const innerObject = Object.values(groupedAbsenceData)[0];

  if (innerObject) {
    if (checkAbsenceType === "rapports") {

      // Convert start and end dates to Date objects for easier comparison
      const start = new Date(startDate);
      const end = new Date(endDate);

      start.setHours(0, 0, 0, 0);
    
      // Set the time to the end of the day for the end date
      end.setHours(23, 59, 59, 999);
      // Filter entries based on date span
      innerObject.entries = innerObject.entries.filter((entry) => {
        const entryDate = new Date(entry.date);
        return entryDate >= start && entryDate <= end;
      });

      // Optionally, recalculate the total_absence_hours based on filtered entries
      innerObject.total_absence_hours = innerObject.entries.reduce(
        (acc, curr) => acc + curr.absence_hours, 
        0
      );
    }

    let absenceAndWorkHours = innerObject.total_absence_hours + week.total_work_hours;
    return innerObject;
  }
  return 0;
};

export default checkForRegisteredAbsence;
