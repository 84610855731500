import React, { useState, useRef, forwardRef } from "react";
import "../styles/NewReportModal.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import postReportArray from "../utils/exportUtils/postReportArray";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';





const getDatesBetween = (startDate, endDate) => {
  const dateArray = [];
  let currentDate = new Date(startDate);
  endDate = new Date(endDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
};

const NewReportModal = ({
  isVisible,
  toggleModal,
  selectedUser,
  users,
  type,
  updateTable,
}) => {
  const [reportCreated, setReportCreated] = useState(false);
  const [newReportUser, setNewReportUser] = useState("");
  const [compensationType, setCompensationType] = useState("Leave");
  const [errorMessage, setErrorMessage] = useState("");
  const [userEmploymentNumber, setUserEmploymentNumber] = useState("");
  const [userEmploymentType, setUserEmploymentType] = useState("");
  const [absenceType, setAbsenceType] = useState("");
  const [startTime, setStartTime] = useState("07:00");
  const [endTime, setEndTime] = useState("16:00");
  const [breakTime, setBreakTime] = useState("60");
  const [workHours, setWorkHours] = useState("8");
  const [comp_30, setComp_30] = useState("0");
  const [comp_50, setComp_50] = useState("0");
  const [comp_70, setComp_70] = useState("0");
  const [comp_100, setComp_100] = useState("0");
  const [userLocation, setUserLocation] = useState("");
  const [has_allowance, setHas_allowance] = useState(false);
  const [has_travel_expences, setHas_travel_expences] = useState(false);
  const [out_call, setOut_call] = useState(false);
  const [activeTab, setActiveTab] = useState(type);
  const [showEndDate, setShowEndDate] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [startDisplayDate, setStartDisplayDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDisplayDate, setEndDisplayDate] = useState("");
  const [inputValue, setInputValue] = useState("");
  const datePickerRef = useRef(null);
  const dateEndPickerRef = useRef(null);

  const handleInputClick = () => {
    // Programmatically open the date picker
    datePickerRef.current.setFocus();
  };

  const handleEndInputClick = () => {
    // Programmatically open the date picker
    dateEndPickerRef.current.setFocus();
  };
  
  const handleStartDateChange = (date) => {
    if (date) {
      const adjustedDate = new Date(date.getTime() + 2 * 60 * 60 * 1000); // Adding 2 hours in milliseconds

      const formattedDate = adjustedDate.getFullYear() + '-' +
                            ('0' + (adjustedDate.getMonth() + 1)).slice(-2) + '-' +
                            ('0' + adjustedDate.getDate()).slice(-2);
      setStartDate(adjustedDate);
      setStartDisplayDate(formattedDate); // Update input value with formatted date
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const adjustedDate = new Date(date.getTime() + 2 * 60 * 60 * 1000); // Adding 2 hours in milliseconds

      const formattedDate = adjustedDate.getFullYear() + '-' +
                            ('0' + (adjustedDate.getMonth() + 1)).slice(-2) + '-' +
                            ('0' + adjustedDate.getDate()).slice(-2);
      setEndDate(adjustedDate);
      setEndDisplayDate(formattedDate); // Update input value with formatted date
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#E2E9F580",
      border: "none",
      borderRadius: "10px",
      height: "25px",
      width: "100%",
      textIndent: "2px",
      fontSize: "13px",
      fontWeight: "normal",
      zIndex: "1", // Lower z-index for the control element (where the placeholder and selected value are shown)
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "100", // Higher z-index for the dropdown menu when expanded
    }),
    option: (styles) => ({
      ...styles,
      zIndex: "100", // Ensure individual options have a higher z-index
    }),
  };
  

  const compensationOptions = [
    { value: "Leave", label: "Ledighet" },
    { value: "Salary", label: "Lön" },
  ];

  const absence_types = [
    { value: "ATF", label: "ATF" },
    { value: "Sjukfrånvaro", label: "Sjukfrånvaro" },
    { value: "Bet. Semester", label: "Bet. Semester" },
    { value: "Obet. Semester", label: "Obet. Semester" },
    { value: "Tjänsteledighet", label: "Tjänsteledighet" },
    { value: "Permission", label: "Permission" },
    { value: "Föräldraledighet", label: "Föräldraledighet" },
    { value: "VAB", label: "VAB" },
    { value: "Uttag av komp.tid", label: "Uttag av komp.tid" },
    { value: "Otillåten frånvaro", label: "Otillåten frånvaro" },
  ];

  let activeUser = "";
  if (users.length > 1) {
    activeUser = users.find((user) => user.value === selectedUser).label;
  }
  const resetStates = () => {
    setCompensationType("Leave");
    setAbsenceType("");
    setStartTime("07:00");
    setEndTime("16:00");
    setBreakTime("60");
    setWorkHours("8");
    setComp_30("");
    setComp_50("");
    setComp_70("");
    setComp_100("");
    setUserLocation("");
    setHas_allowance(false);
    setHas_travel_expences(false);
    setOut_call(false);
    updateTable(selectedUser);
    setErrorMessage("");
  };

  const handleSubmit = () => {
    let dates = getDatesBetween(startDate, endDate);

    if (endDate === "") {
      dates = getDatesBetween(startDate, startDate);
    }
    const reportsArray = [];

    setErrorMessage("");
    if (!newReportUser || !startDate || (showEndDate && !endDate)) {
      setErrorMessage("Välj en användare och datum");
      return;
    }
    if (!startTime || !endTime) {
      setErrorMessage("Välj start- och sluttid");
      return;
    }
    if (activeTab === "timereport") {
      if (!compensationType) {
        setErrorMessage("Välj kompensationstyp");
        return;
      }
      if (!workHours) {
        setErrorMessage("Ange arbetstid");
        return;
      }
    } else if (activeTab === "absence") {
      if (!absenceType) {
        setErrorMessage("Välj en frånvarotyp");
        return;
      }
    }

    for (const date of dates) {
      let report = null;
      if (activeTab === "absence") {
        report = {
          user: newReportUser,
          date,
          type: "absence",
          employmentNumber: userEmploymentNumber,
          employmentType: userEmploymentType,
          startTime,
          endTime,
          absenceType,
        };
      } else {
        report = {
          user: newReportUser,
          date,
          type: "timereport",
          employmentNumber: userEmploymentNumber,
          employmentType: userEmploymentType,
          startTime,
          endTime,
          compensationType,
          breakTime,
          workHours,
          comp_30,
          comp_50,
          comp_70,
          comp_100,
          location: userLocation,
          has_allowance,
          has_travel_expenses: has_travel_expences,
          out_call,
        };
      }
      reportsArray.push(report);
    }
    postReportArray(reportsArray, activeTab)
      .then((data) => {
        if (data.req && data.req.status === 200) {
          setReportCreated(true);
          toggleModal();
          setErrorMessage("Rapporten skapades");
        } else {
          setErrorMessage(
            "Något gick fel, kontrollera så att tiden och datumet inte överlappar med en annan rapport och försök igen"
          );
        }
      })
      .catch((error) => {
        setErrorMessage("Ett nätverksfel inträffade, försök igen");
      });
    resetStates();
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "timereport":
        return (
          <>
            <div className="grid-container">
              <label className="inputLabel">
                TIMMAR
                <input
                  className="addUserTimeInput"
                  placeholder="TIMMAR (8 timmar förvalt)"
                  type="number"
                  icon="none"
                  onChange={(e) => {
                    setWorkHours(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </label>
              <label className="inputLabel">
                RAST
                <input
                  className="addUserTimeInput"
                  placeholder="RAST (60 minuter förvalt)"
                  type="number"
                  icon="none"
                  onChange={(e) => {
                    setBreakTime(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </label>
              <label className="inputLabel">
                ORT
                <input
                  className="addUserTimeInput"
                  placeholder="ORT"
                  type="text"
                  icon="none"
                  onChange={(e) => {
                    setUserLocation(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </label>
              <label className="inputLabel">
                ÖT 16-17 30%
                <input
                  className="addUserTimeInput"
                  placeholder="ÖT 30 16-17"
                  type="number"
                  icon="none"
                  onChange={(e) => {
                    setComp_30(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </label>
              <label className="inputLabel">
                ÖT 17-19 50%
                <input
                  className="addUserTimeInput"
                  placeholder="ÖT 50 17-19"
                  type="number"
                  icon="none"
                  onChange={(e) => {
                    setComp_50(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </label>
              <label className="inputLabel">
                ÖT 19-22 70%
                <input
                  className="addUserTimeInput"
                  placeholder="ÖT 70 19-22"
                  type="number"
                  icon="none"
                  onChange={(e) => {
                    setComp_70(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </label>
              <label className="inputLabel">
                ÖT 22-05 100%
                <input
                  className="addUserTimeInput"
                  placeholder="ÖT 100 22-05"
                  type="number"
                  icon="none"
                  onChange={(e) => {
                    setComp_100(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </label>
            </div>
            <div className="grid-container">
              <div className="userSelect">
                <label className="inputLabel">
                  {" "}
                  KOMPENSATIONSTYP
                  <Select
                    placeholder="KOMPENSATIONSTYP (förvalt ledighet)"
                    styles={colourStyles}
                    options={compensationOptions}
                    onChange={(e) => {
                      setCompensationType(e.value);
                      setErrorMessage("");
                    }}
                  />
                </label>
              </div>
            </div>

            <div className="toggleContainerBottom">
              <p className="toggleLabel"> RESERSÄTTNING</p>
              <label className="toggle-switchNewReport">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setHas_travel_expences(!has_travel_expences);
                  }}
                />
                <span className="switch" />
              </label>
              <p className="toggleLabel"> TRAKTAMENTE</p>

              <label className="toggle-switchNewReport">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setHas_allowance(!has_allowance);
                  }}
                />
                <span className="switch" />
              </label>
              <p className="toggleLabel"> UTRYCKNING</p>

              <label className="toggle-switchNewReport">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setOut_call(!out_call);
                  }}
                />
                <span className="switch" />
              </label>
            </div>
          </>
        );
      case "absence":
        return (
          <>
            <div className="grid-container">
              <div className="userSelect">
                <label className="inputLabel">
                  FRÅNVAROTYP
                  <Select
                    placeholder="Frånvarotyp"
                    options={absence_types}
                    styles={colourStyles}
                    onChange={(e) => {
                      setAbsenceType(e.value);
                      setErrorMessage("");
                    }}
                  />
                </label>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {isVisible ? (
        <div className="newReportModal-overlay">
          <div className="modal">
            <div className="tab-nav">
              <div
                className={`tab-container ${
                  activeTab === "timereport" ? "active" : ""
                }`}
              >
                <button
                  className={`tab-button ${
                    activeTab === "timereport" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("timereport")}
                >
                  TIDSRAPPORT
                </button>
              </div>
              <div
                className={`tab-container ${
                  activeTab === "absence" ? "active" : ""
                }`}
              >
                <button
                  className={`tab-button ${
                    activeTab === "absence" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("absence")}
                >
                  FRÅNVARORAPPORT
                </button>
              </div>
            </div>

            <div className="modal-title-container">
              <h2 className="modal-title">Lägg till ny rapport</h2>
              <div className="toggleContainer">
                <p className="toggleLabel"> SLUTDATUM</p>
                <label className="toggle-switchNewReport">
                  <input
                    type="checkbox"
                    checked={showEndDate}
                    onChange={() =>
                      setShowEndDate(!showEndDate) + setEndDate("")
                    }
                  />
                  <span className="switch" />
                </label>
              </div>
            </div>

            <div className="modal-header"></div>
            <div className="modal-body">
              <div className="grid-container">
                <div className="userSelect">
                  <label className="inputLabel">
                    ANVÄNDARE
                    <Select
                      placeholder="ANVÄNDARE"
                      options={users}
                      styles={colourStyles}
                      onChange={(e) => {
                        setNewReportUser(e.value);
                        setErrorMessage("");
                        setUserEmploymentNumber(e.employee_number);
                        setUserEmploymentType(e.employee_employment);
                      }}
                    />
                  </label>
                </div>
                <label className="inputLabel">
                  STARTTID
                  <input
                    className="addUserTimeInput"
                    placeholder="STARTTID"
                    type="time"
                    icon="none"
                    defaultValue={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                      setErrorMessage("");
                    }}
                  ></input>
                </label>
                <label className="inputLabel">
                  SLUTTID
                  <input
                    className="addUserTimeInput"
                    placeholder="SLUTTID"
                    type="time"
                    icon="none"
                    defaultValue={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                      setErrorMessage("");
                    }}
                  ></input>
                </label>
                <label className="inputLabel" style={{ position: 'relative' }}>
                  STARTDATUM
                  <input
                    className="addUserTimeInput"
                    type="text"
                    placeholder="yyyy-mm-dd"
                    value={startDisplayDate} // Assuming inputValue is from your state
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      // Format the value to match yyyy-mm-dd
                      const formattedValue = e.target.value
                        .replace(/[^\d]/g, '') // Remove any non-numeric characters
                        .replace(/(\d{4})(\d{0,2})(\d{0,2})/, (match, p1, p2, p3) => {
                          let result = p1; // Start with the year part
                          if (p2) result += '-' + p2; // Add the month part if available
                          if (p3) result += '-' + p3; // Add the day part if available
                          return result;
                        });

                      // Update the input value with the formatted string
                      setStartDisplayDate(formattedValue);
                      // Check if the input value is a full date in the correct format
                      if (formattedValue.length === 10 && /^\d{4}-\d{2}-\d{2}$/.test(formattedValue)) {
                        // Parse the date string and update DatePicker
                        const [year, month, day] = formattedValue.split('-').map(Number);
                        const parsedDate = new Date(year, month - 1, day); // Note: month is 0-based in Date()
                        console.log(parsedDate);
                        if (!isNaN(parsedDate)) {
                          // Set DatePicker value
                          setStartDate(parsedDate);
                        }
                      }
                    }
                   // Select all text when the input is focused
  
                  }
                  />

                  {/* FontAwesome Icon Button */}
                  <button
                    type="button"
                    style={{
                      position: 'absolute',
                      color: '#404041',
                      right: '10px',
                      top: '50%', // Center vertically relative to the input
                      transform: 'translateY(-50%)', // Adjust to perfectly center the button
                      background: 'none', // Remove default button background
                      border: 'none', // Remove default button border
                      cursor: 'pointer', // Make the button clickable
                      display: 'flex', // Center the icon horizontally
                      alignItems: 'center', // Vertically align the icon
                      justifyContent: 'center', // Horizontally center the icon
                      zIndex: 10 // Ensure the button stays above the input
                    }}
                    onClick={handleInputClick} // Assuming you have this function to handle clicks
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} size="sm" />
                  </button>
            

                {/* Hidden DatePicker input, but date selection still possible */}
                <DatePicker
                  ref={datePickerRef} // Use ref to programmatically control the DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange} // Set the date when selected
                  dateFormat="yyyy-MM-dd"
                  onKeyDown={(e) => e.preventDefault()} // Prevent manual typing in DatePicker
                  popperPlacement="bottom-start" // Control where the calendar pops up
                 
                  // Hide the input by providing a custom input that does nothing
                />
                </label>
                {showEndDate && (
                  <label className="inputLabel" style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  SLUTDATUM
                  <input
                    className="addUserTimeInput"
                    type="text"
                    placeholder="yyyy-mm-dd"
                    value={endDisplayDate} // Assuming inputValue is from your state
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      // Format the value to match yyyy-mm-dd
                      const formattedValue = e.target.value
                        .replace(/[^\d]/g, '') // Remove any non-numeric characters
                        .replace(/(\d{4})(\d{0,2})(\d{0,2})/, (match, p1, p2, p3) => {
                          let result = p1; // Start with the year part
                          if (p2) result += '-' + p2; // Add the month part if available
                          if (p3) result += '-' + p3; // Add the day part if available
                          return result;
                        });
                
                      // Update the input value with the formatted string
                      setEndDisplayDate(formattedValue);
                
                      // Check if the input value is a full date in the correct format
                      if (formattedValue.length === 10 && /^\d{4}-\d{2}-\d{2}$/.test(formattedValue)) {
                        const [year, month, day] = formattedValue.split('-').map(Number);
                        const parsedDate = new Date(year, month - 1, day); // Note: month is 0-based in Date()
                        if (!isNaN(parsedDate)) {
                          // Set DatePicker value
                          setEndDate(parsedDate);
                        }
                      }
                    }}
                  />
                
                  {/* FontAwesome Icon Button */}
                  <button
                    type="button"
                    style={{
                      position: 'absolute',
                      color: '#404041',
                      right: '10px',
                      top: '50%', // Align the button to the middle of the input field
                      transform: 'translateY(-50%)', // Correctly center vertically
                      height: '100%', // Ensure it matches the height of the input field
                      background: 'none', // Remove default button background
                      border: 'none', // Remove default button border
                      cursor: 'pointer', // Make the button clickable
                      zIndex: 10 // Ensure the button stays above the input
                    }}
                    onClick={handleEndInputClick} // Assuming you have this function to handle clicks
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} size="sm" />
                  </button>
                
                  {/* Hidden DatePicker input, but date selection still possible */}
                  <DatePicker
                    ref={dateEndPickerRef} // Use ref to programmatically control the DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange} // Set the date when selected
                    dateFormat="yyyy-MM-dd"
                    onKeyDown={(e) => e.preventDefault()} // Prevent manual typing in DatePicker
                    popperPlacement="bottom-start"
                  />
                </label>
                
                )}
              </div>
              {renderTabContent()}
              {errorMessage ? <p>{errorMessage}</p> : <div></div>}
            </div>

            <div className="newReportModalButtonContainer">
              <button
                onClick={() =>
                  resetStates() +
                  toggleModal() +
                  setReportCreated(!reportCreated)
                }
                className="closeNewReportModal"
              >
                Avbryt
              </button>
              <button className="addUserButton" onClick={handleSubmit}>
                Lägg till
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {reportCreated ? (
        <div className="userFeedbackModal">
          <div className="userFeedBackModalContent"></div>
        </div>
      ) : null}
    </div>
  );
};

export default NewReportModal;
