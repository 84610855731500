import { fetchWithTimeout } from "../fetchWithTimeout";

async function updateCertified(status, id, path, array) {
  if (array) {
    array.forEach(async function (item) {
      item.certified = parseInt(item.certified);
      item.certified = !item.certified;
      const customerURL = localStorage.getItem("customerUrl");
      const userToken = localStorage.getItem("token");
      var url = customerURL + "/wp-json/tidig/v1/updatestatus";
      const object = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken,
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          id: item.id,
          status: status,
          path: path,
        }),
      };
      const data = await fetchWithTimeout(url, object)
        .then((response) => response.json())
        .then((responseJson) => {
          return responseJson.req;
        })
        .catch((error) => {
          console.error(error, "this is the error");
        });
      return await data;
    });
  } else {
    const customerURL = localStorage.getItem("customerUrl");
    const userToken = localStorage.getItem("token");
    var url = customerURL + "/wp-json/tidig/v1/updatestatus";
    const object = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        id: id,
        status: status,
        path: path,
      }),
    };
    const data = await fetchWithTimeout(url, object)
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson.req;
      })
      .catch((error) => {
        console.error(error, "this is the error");
      });
    return await data;
  }
}
export { updateCertified };
