import { fetchWithTimeout } from "../fetchWithTimeout";

async function updateCertifiedArray(certifiedArray, isApprove, path) {
  const customerURL = localStorage.getItem("customerUrl");
  const userToken = localStorage.getItem("token");

  var url = customerURL + "/wp-json/tidig/v1/update_status_array";

  // Toggle 'certified' value for each item in the array and add 'path'
  certifiedArray.forEach(item => {
    isApprove ? item.certified = 1 : item.certified = 0;
    //item.certified = item.certified === 1 ? 0 : 1;
    item.path = path;
  });

  const object = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      requests: certifiedArray
    }),
  };
  
  const data = await fetchWithTimeout(url, object)
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson.req;
    })
    .catch((error) => {
      console.error(error, "this is the error");
    });
  
  return await data;
}

export { updateCertifiedArray };
