const majorHolidays = {
  trettondagen: "none",
  långfredagen: "none",
  annandagpåsk: "none",
  första_maj: "none",
  kristi_himmelfärdsdag: "none",
  nationaldagen: "none",
  midsommarafton: "none",
  julafton: "none",
  juldagen: "none",
  annandagjul: "none",
  nyårsafton: "none",
  nyårsdagen: "none",
};

const freeDays = {
  julafton: "none",
  nyårsafton: "none",
  påskafton: "none",
  pingst: "none",
  midsommarafton: "none",
};

async function checkForHoliday(country = "SE", year) {
  const key = "9f6820ce-4832-4b37-a918-8a3af2ff6e0d";
  const response = await fetch(
    `https://holidayapi.com/v1/holidays?key=${key}&country=${country}&year=${year}`
  );
  const data = await response.json();
  // holidays to get : trettondagen, långredag, annandagpåsk, första_maj, kristi_himmelfärdsdag, nationaldagen, midsommarafton, julafton, juldagen, annandagjul, nyårsafton, nyårsdagen
  const publicHolidays = data.holidays.filter(
    (holiday) =>
      holiday.name === "Epiphany" ||
      holiday.name === "Good Friday" ||
      holiday.name === "Easter" ||
      holiday.name === "Easter Monday" ||
      holiday.name === "may 1st" ||
      holiday.name === "Feast of the Ascension of Jesus Christ" ||
      holiday.name === "Pentecost" ||
      holiday.name === "National Day" ||
      holiday.name === "Midsummer Eve" ||
      holiday.name === "All Saints day" ||
      holiday.name === "Christmas Eve" ||
      holiday.name === "Christmas Day" ||
      holiday.name === "Boxing Day" ||
      holiday.name === "New Year's Eve" ||
      holiday.name === "New Year's Day"
  );

  return await publicHolidays;
}

export default checkForHoliday;
