import { findCostCenter } from "./payTypeIdIndex";

const createReimbursementObject = (week, quantity, payTypeId) => ({
    periodStartDate: week.start_date,
    periodEndDate: week.end_date,
    quantity: quantity,
    employmentId: week.employment_number,
    payTypeCode: "L",
    payTypeId: payTypeId,
    costCenter: findCostCenter(payTypeId, week.employment_type),
  });

  export default createReimbursementObject;