import React from 'react';
import Select from 'react-select';


const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "#F2F5FA",
        border: "none",
        borderRadius: "10px",
        height: "51px",
        width: "259px",
    }),
};

const UserSelect = ({ users, requestDataArray, setRequestDataArray, userFilter, setSelectedUser, handleRender }) => (
    <div className="userFilter">
        <div className="userFilterSubContainer">
            <p className="subHeaderText">ANVÄNDARE</p>
            <div className="filterContainer">
                <Select
                    placeholder="Användare"
                    options={users}
                    styles={colourStyles}
                    onChange={(e) => {
                        const requestDataArrayCopy = [...requestDataArray];
                        requestDataArrayCopy[0] = e.value;
                        setRequestDataArray(requestDataArrayCopy);
                        userFilter(requestDataArrayCopy);
                        setSelectedUser(e.value);
                        handleRender();
                    }}
                />
            </div>
        </div>
    </div>
);

export default UserSelect;
