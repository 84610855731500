import React, { useState, use, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getCustomerUrl from "../utils/renderUtils/getCustomerUrl";
import "../styles/LoginForm.css";

const LoginForm = ({ handleLoggedInUserData, setIsAuthenticated }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const authenticateUser = async (event) => {
    event.preventDefault();
    const customerUrl = await getCustomerUrl(username);
    localStorage.setItem("customerUrl", customerUrl);
    const url = customerUrl + "/wp-json/aam/v2/authenticate";
    const credentials = `Basic ${btoa(`${username}:${password}`)}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: credentials,
      },
      body: JSON.stringify({
        username: username,
        password: password,
        issueJWT: true,
      }),
    })
      .then((response) => {
        const response2 = response.json();
        return response2;
       })
      .then((response) => {
        if (response.jwt.token) {
          // Save the JWT token returned by the server in local storage
          // Set the 'token' state variable to the JWT token
          setToken(response.jwt.token);
          localStorage.setItem("token", response.jwt.token);
          handleLoggedInUserData(response.user.data.display_name);
          localStorage.setItem("username", response.user.data.display_name);
          // Redirect the user to the protected page
          navigate("/timereport");
        } else {
          setError(response.message);
        }
      })
      .catch((error) => {
        setError("An error occurred. Please try again.");
      });
  };
  useEffect(() => {
    if (token) {
      // if the response.valid is true, set the isAuthenticated state variable to true
      // if the response.valid is false, set the isAuthenticated state variable to false

      const url =
        localStorage.getItem("customerUrl") + "/wp-json/aam/v2/jwt/validate";
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          jwt: token,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.isValid === true) {
            setIsAuthenticated(true);
            setToken(localStorage.getItem("token"));
            handleLoggedInUserData(localStorage.getItem("username"));
            navigate("/timereport");
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            navigate("/login");
          }
        });
    }
  }, []);
  return (
    <form onSubmit={authenticateUser} className="loginForm">
      <div className="userName__container">
        <p className="userName__header">E-POSTADRESS</p>
        <label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </label>
      </div>
      <br />
      <p className="userName__header">LÖSENORD</p>
      <label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </label>
      <br />
      {error && <p>{error}</p>}
      <button type="submit" id="submit">LOGGA IN</button>
    </form>
  );
};
export default LoginForm;
