import React, { useState, useEffect } from "react";
import { patchAbsenceReport } from "../utils/handleReportsUtils/patchAbsenceReport";
import "../styles/EditableInput.css";

const EditableAbsenceData = ({ editFields, value, type, id, handleRender }) => {
  if (value === null) {
    value = "";
  }
  const [inputValue, setInputValue] = useState(value);

  const handleChange = async (e) => {
    setInputValue(e.target.value);
    await patchAbsenceReport(id, type, e.target.value);
    handleRender();
  };

  const handleBlur = (e) => {
    handleChange(e);
    handleRender();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleChange(e);
      handleRender();
    }
  };

  let total = value;
  useEffect(() => {
    setInputValue(total);
    handleRender();
  }, [total]);

  return (
    <input
      className="editableInput"
      readOnly={editFields}
      defaultValue={inputValue}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
    />
  );
};

export default EditableAbsenceData;
