import React from "react";
import { updateCertified } from "../utils/handleReportsUtils/updateCertified";
import "../styles/StatusSymbol.css";

function StatusSymbol({
  bool,
  id,
  handleRender,
  path,
  currentPageData,
  activeData,
}) {
  if (bool === "1") {
    const status = 0;
    return (
      <div className="status-symbol">
        <button
          className="symbolButton"
          onClick={(e) => {
            updateCertified(status, id, path);
            handleRender();
          }}
        >
          <div className={"green"}></div>
        </button>
      </div>
    );
  } else {
    const status = 1;
    return (
      <div className="status-symbol">
        <button
          className="symbolButton"
          onClick={(e) => {
            updateCertified(status, id, path);
            handleRender();
          }}
        >
          <div className={"black"}></div>
        </button>
      </div>
    );
  }
}
export default StatusSymbol;
