import createReimbursementObject from "./createReimbursementObject";

function handleReimbursements(week, restructureData) {
  if (week.has_travel_expences > 0) {
    restructureData.push(
      createReimbursementObject(week, week.has_travel_expences, 800)
    );
  }
  if (week.out_call > 0) {
    restructureData.push(createReimbursementObject(week, week.out_call, 135));
  }
  if (week.has_allowance > 0) {
    restructureData.push(
      createReimbursementObject(week, week.has_allowance, 845)
    );
  }
}

export default handleReimbursements;
