import { findCostCenter } from "./payTypeIdIndex";

async function createRegularPayObject(
  week,
  restructureData,
  overTimeWithoutCompensation,
  holidayHours,
  weekendHours,
  numberOfHoursToRegisterAsNonRegistredComp
) {

  let quantity = week.total_work_hours + weekendHours + holidayHours;

  quantity = quantity - week.total_comp_hours - overTimeWithoutCompensation - numberOfHoursToRegisterAsNonRegistredComp;

  let payTypeId = 0;
  let costCenter = 0;
  if (week.employment_type === "hour") {
    payTypeId = 10;
    costCenter = findCostCenter(payTypeId, week.employment_type);
  } else if (week.employment_type === "month") {
    payTypeId = 31;
    costCenter = findCostCenter(payTypeId, week.employment_type);
  }
  if (quantity > 0) {
    restructureData.push({
      periodStartDate: week.start_date,
      periodEndDate: week.end_date,
      quantity: quantity,
      employmentId: week.employment_number,
      payTypeCode: "L",
      payTypeId: payTypeId,
      costCenter: findCostCenter(payTypeId, week.employment_type),
    });
  }
}
export default createRegularPayObject;
