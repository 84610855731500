import React, { useEffect, useState } from "react";
import MonthCalendar from "../components/Calendar/Calendar";
import CalendarHeader from "../components/Calendar/CalendarHeader";
import "../styles/CalendarView.css";
import { fetchWithTimeout } from "../utils/fetchWithTimeout";
import { getAllUserNames } from "../utils/renderUtils/getAllUserNames";
import {calculateWorkHours, calculateTotalDataForMonth} from "../utils/calenderViewUtils/CalculateCalendarHours";


const CalendarView = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
    const [userReports, setUserReports] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [users, setUsers] = useState([]);
    const [numberOfReports, setNumberOfReports] = useState(0);
    const [monthWorkHours, setMonthWorkHours] = useState(0);
    const [monthAbsenceHours, setMonthAbsenceHours] = useState(0);
    const [monthTravelExpenses, setMonthTravelExpenses] = useState(0);
    const [monthAllowance, setMonthAllowance] = useState(0);
    const [monthExclamation, setMonthExclamation] = useState(0);
    const [monthOvertime16_17, setMonthOvertime16_17] = useState(0);
    const [monthOvertime17_19, setMonthOvertime17_19] = useState(0);
    const [monthOvertime19_22, setMonthOvertime19_22] = useState(0);
    const [monthOvertime22_05, setMonthOvertime22_05] = useState(0);

    const addZeroToMonth = (month) => {
        if (month < 10) {
            return "0" + month;
        }
        return month;

    }
    useEffect(() => {
        setSelectedMonth(addZeroToMonth(new Date().getMonth() + 1).toString());
        const fetchData = async () => {
            const usersData = await getAllUserNames();

            const userArray = [{ value: 0, label: "All" }];

            usersData.forEach((user) => {
                const userObject = {
                    value: user.ID,
                    label: user.full_name,
                    employee_number: user.employee_number,
                    employee_employment: user.employee_employment,
                };
                userArray.push(userObject);
            });
            setUsers(userArray);
        };
        fetchData();
    }, []);

    /**
     * Fetches the time reports for the selected user and month
     * and sets the calendar events. Also sumarizes the work hours for the user.
     * to be displayed in the header.
     */
    useEffect(() => {
        const fetchData = async () => {
            const customerURL = localStorage.getItem("customerUrl");
            const userToken = localStorage.getItem("token");
            const url = `${customerURL}/wp-json/tidig/calendar/v1/get_timereports_by_month?month=${selectedMonth}&year=${selectedYear}&employment_number=${selectedUser.employee_number}`;
            let events = [];
            const data = await fetchWithTimeout(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    setUserReports(responseJson);
                    let reports = responseJson;
                    

                    setNumberOfReports(reports.length);
                    const [workHours, absenceHours, totalDaysAllowance, totalDaysOutCall, totalDaysWithTravelExpenses, comp30, comp50, comp70, comp100] = calculateTotalDataForMonth(reports);
                    setMonthWorkHours(workHours);
                    setMonthAbsenceHours(absenceHours);
                    setMonthTravelExpenses(totalDaysWithTravelExpenses);
                    setMonthAllowance(totalDaysAllowance);
                    setMonthExclamation(totalDaysOutCall);
                    setMonthOvertime16_17(comp30);
                    setMonthOvertime17_19(comp50);
                    setMonthOvertime19_22(comp70);
                    setMonthOvertime22_05(comp100);

                    for (let i = 0; i < reports.length; i++) {
                        let report = reports[i];
                        let event = {
                            title: report.absence_type ? calculateWorkHours(report.start_time, report.stop_time, 0, report.absence_type) + " " + report.absence_type : calculateWorkHours(report.start_time, report.stop_time, report.break_time),
                            start: new Date(report.date),
                            end: new Date(report.date),
                            type: report.absence_type ? 'absence' : 'timereport',
                        };
                        events.push(event);
                    }


                })
                .catch((error) => {
                    console.error(error, "this is the error");
                });

                const url_get_free_days = `${customerURL}/wp-json/tidig/calendar/v1/get_free_days_for_user_by_month_and_year?month=${selectedMonth}&year=${selectedYear}&employment_number=${selectedUser.employee_number}`;

                const data_free_days = await fetchWithTimeout(url_get_free_days, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        const resultArray = Object.entries(responseJson); 
                        for (let i = 0; i < resultArray.length; i++) {
                            var statusCode = resultArray[i][1];
                            let event;
                            switch (statusCode) {
                                case 0:
                                    break;
                                case 1:
                                    event = {
                                        title: "Helglön 8:00h",
                                        start: new Date(resultArray[i][0]),
                                        end: new Date(resultArray[i][0]),
                                        type: 'free-day',
                                    };
                                    events.push(event);
                                    break;
                                case 2:
                                    event = {
                                        title: "OBS: Anpassad helglön",
                                        start: new Date(resultArray[i][0]),
                                        end: new Date(resultArray[i][0]),
                                        type: 'free-day-special',
                                    };
                                    events.push(event);
                            }
                            
                            
                            
                        }

                        
                    setCalendarEvents(events);
                    })
                    .catch((error) => {
                        console.error(error, "this is the error");
                    })
            }

        fetchData();
    }, [selectedUser, selectedMonth, selectedYear]);

    const handleNavigate = (date) => {
        const newMonth = addZeroToMonth(date.getMonth() + 1); // Months are zero-indexed

        const newYear = date.getFullYear();

        setSelectedMonth(newMonth.toString());
        setSelectedYear(newYear.toString());
    };






    return (
        <div className="mainContainer">
            <div className="HeaderContainer">
                <div className="Header">
                    <header>Kalender</header>
                </div>
                <div className="Tab"></div>
            </div>
            <CalendarHeader
                users={users}
                setSelectedUser={setSelectedUser}
                numberOfReports={numberOfReports}
                totalHours={monthWorkHours}
                totalBreak={monthAbsenceHours}
                travelExpenses={monthTravelExpenses}
                allowance={monthAllowance}
                exclamation={monthExclamation}
                overtime16_17={monthOvertime16_17}
                overtime17_19={monthOvertime17_19}
                overtime19_22={monthOvertime19_22}
                overtime22_05={monthOvertime22_05}

            />
            <div className="calendar-container">
                <MonthCalendar
                    events={calendarEvents}
                    onNavigate={handleNavigate}
                />
            </div>
        </div>
    );
};

export default CalendarView;
