import { fetchWithTimeout } from "../fetchWithTimeout";

const GetAbsenceTypes = async () => {
  const customerURL = localStorage.getItem("customerUrl");
  const userToken = localStorage.getItem("token");
  let url = customerURL + "/wp-json/tidig/v1/get_absence_types";

  try {
    const response = await fetchWithTimeout(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    return responseData.req.data.map(absence_type => ({
      label: absence_type.name,
      value: absence_type.name,
    }));
  } catch (error) {
    console.error("Failed to fetch absence types:", error);
    return []; // Return an empty array in case of an error
  }
};

export default GetAbsenceTypes;
