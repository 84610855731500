export const groupAbsenceDataByWeek = (data) => {
  const groupedData = data.reduce((acc, curr) => {
    const key = curr.employment_number + "-" + curr.week;
    if (!acc[key]) {
      acc[key] = {
        year: curr.year,
        week: curr.week,
        user_id: curr.user_id,
        total_absence_hours: 0,
        start_date: curr.date,
        end_date: curr.date,
        employment_type: curr.employment_type,
        employment_number: curr.employment_number,
        entries: [],
      };
    }
    const dayOfWeek = new Date(curr.date).getDay();
    
    // Check so that the absence is not a vacation day reported on a weekend. If so, we should not count it.
    if (!(dayOfWeek === 0 || dayOfWeek === 6) || curr.absence_type !== "Bet. Semester") {
      const start = new Date("1970-01-01T" + curr.start_time + "Z");
      const end = new Date("1970-01-01T" + curr.stop_time + "Z");
      const absence_hours = (end - start) / 1000 / 60 / 60;
      const adjusted_hours = absence_hours > 6 ? absence_hours - 1 : absence_hours;
      acc[key].entries.push({
        id: curr.id,
        date: curr.date,
        start_time: curr.start_time,
        stop_time: curr.stop_time,
        absence_hours: adjusted_hours,
        absence_type: curr.absence_type,
      });
      acc[key].total_absence_hours += adjusted_hours;

      if (new Date(curr.date) < new Date(acc[key].start_date)) {
        acc[key].start_date = curr.date;
      }
      if (new Date(curr.date) > new Date(acc[key].end_date)) {
        acc[key].end_date = curr.date;
      }
    }
    return acc;
  }, {});
  return groupedData;
};
