const removeAbsenceDataOutsidePeriod = (startDate, endDate, matchingAbsence) => {
    for (const week of matchingAbsence) {
        for (let i = week.entries.length - 1; i >= 0; i--) {
            const element = week.entries[i];
            const entryDate = new Date(element.date);
            const start = new Date(startDate);
            const end = new Date(endDate);

            start.setHours(0, 0, 0, 0);
            end.setHours(23, 59, 59, 999);

            if (entryDate < start || entryDate > end) {
                week.entries.splice(i, 1);
            }
        }
    }
    return matchingAbsence;
};

export default removeAbsenceDataOutsidePeriod;