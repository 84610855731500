import React, { useState, useEffect } from "react";
import { patchTimeReport } from "../utils/handleReportsUtils/patchTimeReport";
import "../styles/EditableInput.css";

const EditableInput = ({ editFields, value, type, id, handleRender }) => {
  const [inputValue, setInputValue] = useState(value);
  const handleChange = async (e) => {
    setInputValue(e.target.value);
    await patchTimeReport(id, type, e.target.value);
    handleRender();
  };

  const handleBlur = (e) => {
    handleChange(e);
    handleRender();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleChange(e);
      handleRender();
    }
  };
  let total = value;
  useEffect(() => {
    setInputValue(total);
    handleRender();
  }, [total]);
  let className = "editableInput";
  if (type === "note") {
    className ="noteInput";
  }
  return (
    <div className="tooltip">
      <input
        className={className}
        readOnly={editFields}
        defaultValue={inputValue}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
      />
      {type === "note" && inputValue && (
        <span className="tooltiptext">{inputValue}</span>
      )}
    </div>
  );
};

export default EditableInput;
