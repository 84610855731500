import React, { useState } from "react";
import { updateTimes } from "../utils/handleReportsUtils/updateTimes";
import calculateWorkHours from "../utils/renderUtils/calculateWorkHours";
import "../styles/EditableInput.css";

function StopTimeInput({
  editFields,
  value,
  startTime,
  id,
  breakTime,
  handleRender,
  path,
}) {
  const [inputValue, setInputValue] = useState(value);
  const type = "stop_time";

  const formatTimeInput = (input) => {
    if (input && input.length === 4 && !input.includes(":")) {
      return input.substring(0, 2) + ":" + input.substring(2);
    }
    return input;
  };

  const handleInputChange = (e) => {
    const formattedValue = formatTimeInput(e.target.value);
    setInputValue(formattedValue);
  };

  const update = async (e) => {
    if (e.type === "blur" || (e.type === "keydown" && e.key === "Enter")) {
      const workHours = calculateWorkHours(startTime, inputValue, breakTime);
      await updateTimes(id, type, inputValue, workHours, path);
      await handleRender();
    }
  };

  return (
    <input
      className="editableInput"
      readOnly={editFields}
      value={inputValue}
      onChange={handleInputChange}
      onBlur={update}
      onKeyDown={update}
    ></input>
  );
}
export default StopTimeInput;
