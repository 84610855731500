import React from "react";
import { patchAbsenceReport } from "../utils/handleReportsUtils/patchAbsenceReport";
import { patchTimeReport } from "../utils/handleReportsUtils/patchTimeReport";

function ExportToggle({
  element,
  collectExportArray,
  removeItemExportArray,
  handleRender,
  type,
}) {
  let isToggled = "";
  if (element.certified === "1") {
    isToggled = true;
  }
  if (element.certified === "0") {
    isToggled = false;
  }
  const onToggle = () => {
    if (isToggled === true) {
      if (type === "absence") {
        patchAbsenceReport(element.id, "export", 1);
      }
      if (type === "time") {
        patchTimeReport(element.id, "export", 1);
      }
      removeItemExportArray(element);
      handleRender();
    } else {
      if (type === "absence") {
        patchAbsenceReport(element.id, "export", 0);
      }
      if (type === "time") {
        patchTimeReport(element.id, "export", 0);
      }
      collectExportArray(element);
      handleRender();
    }
  };
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isToggled} onChange={() => onToggle()} />
      <span className="switch" />
    </label>
  );
}
export default ExportToggle;
