import moment from 'moment';
import checkIfUserIsEligableForFreeDay from './checkIfUserIsEligableForFreeDay';
import isEmployeeEligibleForFreeDaySalary from './isEmployeeEligibleForFreeDaySalary';

const createFreeHolidayObject = async (week, freeDays, restructureData) => {
    const weekDates = generateWeekDates(week.start_date, week.end_date);
    const entriesDates = [...week.entries, ...week.holidays, ...week.weekEnds].map(entry => entry.date);

    let quantity = 0;
    for (const freeDay of freeDays) {
        // Innehåller veckan en ledig dag och har inte personen jobbat den lediga dagen?
        if (weekDates.includes(freeDay.date)) {
            const weekdayNumeric = parseInt(freeDay.weekday.date.numeric, 10);
            if (weekdayNumeric >= 1 && weekdayNumeric <= 5) {  // Check if free day falls on a weekday
                if (await isEmployeeEligibleForFreeDaySalary(freeDay.date, week.employment_number) == 1) {
                    if (week.employment_type == 'hour') {
                        restructureData.push({
                            periodStartDate: freeDay.date,
                            periodEndDate: freeDay.date,
                            quantity: 8,
                            employmentId: week.employment_number,
                            payTypeCode: "L",
                            payTypeId: 350,
                        });
                        quantity += 8;
                    }
                }
                
            }
        }
    }
    return quantity;
}

// This helper function will generate an array of date strings for each day in the given date range.
function generateWeekDates(start, end) {
    let startDate = moment(start);
    let endDate = moment(end);
    let dates = [];

    while (startDate <= endDate) {
        dates.push(startDate.format('YYYY-MM-DD'));
        startDate = startDate.add(1, 'days');
    }

    return dates;
}

const employeeHasWorkedDayBeforeOrAfterFreeDay = async(employmentNumber, freeDay, freeDays) => {
    /**
     * We need to check wheter the employee has worked the day before or after the free day.
     * However, if the free day is on a monday, we need to check whether the employee has worked
     * on the sunday, saturday or friday the week before.
     * 
     * This check should probably be done in the backend, close to the data... 
     */

    var userIsEligableForFreeDay = false;
    const freeDayDate = freeDay.date;
    var dayBeforeFreeDay = moment(freeDayDate).subtract(1, 'days').format('YYYY-MM-DD');
    var dayAfterFreeDay = moment(freeDayDate).add(1, 'days').format('YYYY-MM-DD');

    // Check if the day before free day is a free day.
    if (freeDays.find(freeDay => freeDay.date === dayBeforeFreeDay) && moment(dayBeforeFreeDay).day() !== 0) {
        dayBeforeFreeDay = moment(dayBeforeFreeDay).subtract(1, 'days').format('YYYY-MM-DD');
    }
    // Check if the day after free day is a free day.
    if (freeDays.find(freeDay => freeDay.date === dayAfterFreeDay) && moment(dayAfterFreeDay).day() !== 6) {
        dayAfterFreeDay = moment(dayAfterFreeDay).add(1, 'days').format('YYYY-MM-DD');
    }

    const employment_number = employmentNumber;
    var days = [];
    /**
     * Check if the day before free day is a sunday. If so, we need to check if the employee has worked
     * on the friday, saturday or sunday the week before.
     */
    if (moment(dayBeforeFreeDay).day() === 0) {
        const dayBeforeFreeDayFriday = moment(dayBeforeFreeDay).subtract(2, 'days').format('YYYY-MM-DD');
        const dayBeforeFreeDaySaturday = moment(dayBeforeFreeDay).subtract(1, 'days').format('YYYY-MM-DD');
        const dayBeforeFreeDaySunday = dayBeforeFreeDay;
        days = [dayBeforeFreeDayFriday, dayBeforeFreeDaySaturday, dayBeforeFreeDaySunday, dayAfterFreeDay];

        // Call the API with the corresponding dates and check if the employee has worked any of these days.
    } else if (moment(dayAfterFreeDay).day() === 6) {
        const dayAfterFreeDaySaturday = dayAfterFreeDay;
        const dayAfterFreeDaySunday = moment(dayAfterFreeDay).add(1, 'days').format('YYYY-MM-DD');
        const dayAfterFreeDayMonday = moment(dayAfterFreeDay).add(2, 'days').format('YYYY-MM-DD');

        days = [dayAfterFreeDaySaturday, dayAfterFreeDaySunday, dayAfterFreeDayMonday, dayBeforeFreeDay];

        // Call the API with the corresponding dates and check if the employee has worked any of these days.
    } else {
            days = [dayBeforeFreeDay, dayAfterFreeDay];
            
    
            // Call the API with the corresponding dates and check if the employee has worked any of these days.
    }

    userIsEligableForFreeDay  = await checkIfUserIsEligableForFreeDay(days, employment_number);

    return userIsEligableForFreeDay;

    



    

    
}

export default createFreeHolidayObject;