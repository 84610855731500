function getWeekDates(date) {
  const inputDate = new Date(date);
  const dayOfWeek = inputDate.getUTCDay();
  const diffToMonday =
    inputDate.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  const monday = new Date(inputDate.setUTCDate(diffToMonday));
  const sunday = new Date(monday.getTime() + 6 * 24 * 60 * 60 * 1000);
  return {
    first: monday.toISOString().slice(0, 10),
    last: sunday.toISOString().slice(0, 10),
  };
}
export default getWeekDates;
