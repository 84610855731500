import React, { useState, useEffect } from "react";
import "../styles/EditableInput.css";

function TotalTime({ value, value2, breakTime, isExport, id, addTime }) {
  const calculateTotalTime = (value, value2, breakTime) => {
    let hours = parseInt(value.split(":")[0]);
    let minutes = parseInt(value.split(":")[1]);
    let hours2 = parseInt(value2.split(":")[0]);
    let minutes2 = parseInt(value2.split(":")[1]);

    let hoursDiff = hours2 - hours;
    let minutesDiff = minutes2 - minutes;

    // Convert breakTime to hours and minutes
    let breakHours = 0;
    if (breakTime !== undefined && breakTime !== null) {
      breakTime = parseInt(breakTime);
      breakHours = Math.floor(breakTime / 60);
      breakTime = breakTime % 60;
    } else {
      breakTime = (hoursDiff >= 6) ? 60 : 0;
    }

    // Subtract break time
    minutesDiff -= breakTime;
    hoursDiff -= breakHours;

    // Adjust time calculations
    if (minutesDiff < 0) {
      minutesDiff += 60;
      hoursDiff--;
    }
    if (minutesDiff >= 60) {
      minutesDiff -= 60;
      hoursDiff++;
    }
    if (hoursDiff < 0) {
      hoursDiff += 24;
    }

    // Format the time
    hoursDiff = hoursDiff < 10 ? "0" + hoursDiff : hoursDiff;
    minutesDiff = minutesDiff < 10 ? "0" + minutesDiff : minutesDiff;

    return hoursDiff + ":" + minutesDiff;
  };

  let total = "00:00";
  if (value2 && value && breakTime !== undefined) {
    total = calculateTotalTime(value, value2, breakTime);
  }

  const [inputValue, setInputValue] = useState(total);

  useEffect(() => {
    setInputValue(total);
  }, [total, isExport]);

  return (
    <input
      className="editableInput"
      readOnly={true}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
}

export default TotalTime;
