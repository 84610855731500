// CustomEvent.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClockRotateLeft, faMugHot } from '@fortawesome/free-solid-svg-icons';

const getIconForEventType = (type) => {
    switch (type) {
        case 'timereport':
            return faClockRotateLeft;
        case 'absence':
            return faMugHot;
        case 'free-day':
            return faCalendar;
            case 'free-day-special':
            return faCalendar;
        case 'product-release':
            return faCalendar;
        case 'competition':
            return faCalendar;
        default:
            return faCalendar;
    }
};

const CustomEvent = ({ event }) => {
    const icon = getIconForEventType(event.type);

    return (
        <span className={`custom-event ${event.type}`}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: '5px' }} />
            {event.title}
    </span>
    );
};

export default CustomEvent;
