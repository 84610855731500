import "../styles/TopNavBar.css";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function TopNavBar({ loggedInUserData, logoutUser, customerLogo, title }) {
  return (
    <nav className="navigation">
      <div>
        <p href="/" className="brand-name">
          {title}
        </p>
      </div>
      <div className="right-side-display">
        <div className="user-name">
          <h3>{loggedInUserData}</h3>
          <div className="logoutContainer">
            <button onClick={() => logoutUser()} className="logoutButton">
              Logga ut
            </button>
          </div>
        </div>
        <div>
          <img
            alt="logo"
            className="customerLogoTopNav"
            src={customerLogo}
          ></img>
        </div>
      </div>
    </nav>
  );
}

export default TopNavBar;
