import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteTimeReport } from "../utils/handleReportsUtils/deleteTimeReport";
import "../styles/StatusSymbol.css";

function DeleteComponent({ id, handleRender, path }) {
  return (
    <div
      className="deleteButton"
      onClick={() => deleteTimeReport(id, path) + handleRender()}
    >
      <FontAwesomeIcon icon={faTrash} size="xs" />
    </div>
  );
}
export default DeleteComponent;
