import React, { useState } from "react";
import { patchTimeReport } from "../utils/handleReportsUtils/patchTimeReport";
import "../styles/ToggleSwitch.css";

function ToggleSwitch({ bool, editFields, id, type, handleRender }) {
  // convert the bool to a boolean, if 1 its true and 0 false

  if (bool === "1") {
    bool = true;
  } else {
    bool = false;
  }

  const [isToggled2, setIsToggled2] = useState(bool);
  const onToggle = () => {
    if (editFields === false) {
      const value = isToggled2 ? 0 : 1;
      setIsToggled2(!isToggled2);
      patchTimeReport(id, type, value);
      handleRender();
    }
  };
  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isToggled2}
        onChange={onToggle}
        readOnly={editFields}
      />
      <span className="switch" />
    </label>
  );
}
export default ToggleSwitch;
