import React from "react";
import {updateEditable} from "../utils/handleReportsUtils/updateEditable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons";



function EditableSymbol({bool, id, handleRender}) {
    // return a little circle that's either green or black depending on the bool

    if (bool==="1") {
        const status = 0
    return (
        <div className="status-symbol">
        <button className="editableSymbolButton" onClick={() => updateEditable(status, id) + handleRender()}>
            <div className="lockContainer">
        <FontAwesomeIcon icon={faLockOpen} size="1x" />
        </div>
            </button>
        </div>
 
    );
    }
    else {
        const status = 1
        return (
            <div className="status-symbol">
            <button className="editableSymbolButton" onClick={() => updateEditable(status, id) + handleRender()}>
            <div className="lockContainer">
        <FontAwesomeIcon icon={faLock} size="1x" />
        </div>
            </button>
        </div>
        );
    }
  }
  export default EditableSymbol;