import * as XLSX from "xlsx";
import { fetchAbsenceReports } from "../handleReportsUtils/fetchAbsenceReports";
import getPeriodWeeks from "./getPeriodWeeks";
import getStartAndEndDates from "./getStartAndEndDates";

const exportToExcel = async (data, entireWeekData, usersList, requestDataArray, path, setIsLoadingExcel) => {
  const period = getPeriodWeeks(data[0].week, data[0].date);
  const startAndEndDates = getStartAndEndDates(period, data[0].year);
  const absenceReports = await fetchAbsenceReports(startAndEndDates.start, startAndEndDates.end, requestDataArray);
  if (absenceReports) {
    absenceReports.map((item) => {
      // format data
      item.date = item.date.slice(0, 10);
      item.start_time = item.start_time.slice(0, 5);
      item.stop_time = item.stop_time.slice(0, 5);
      item.user_id = parseInt(item.user_id);

      usersList.map((user) => {
        user.value = parseInt(user.value);
        if (item.user_id === user.value) {
          // add the username to the item
          item.user_name = user.label;
        } else {
          // do nothing
        }
      });
    });
  }
  /* Create the headers row */
  let headers = Object.keys(data[0]);

  // Sort headers with priority
  const priorityHeaders = [
    "user_name",
    "user_id",
    "date",
    "week",
    "start_time",
    "stop_time",
    "work_hours",
    "break_time",
    "comp_30",
    "comp_50",
    "comp_70",
    "comp_100",
    "has_allowance",
    "has_travel_expences",
    "out_call",
  ];
  
  headers.sort((a, b) => {
    if (priorityHeaders.includes(a) && priorityHeaders.includes(b)) {
      return priorityHeaders.indexOf(a) - priorityHeaders.indexOf(b);
    }
    if (priorityHeaders.includes(a)) {
      return -1;
    }
    if (priorityHeaders.includes(b)) {
      return 1;
    }
    return 0;
  });

  // Rearrange each row according to the sorted headers
  const rearrangedRows = data.map((row) => {
    return headers.map((h) => row[h]);
  });

  // Sort rows by user_id and then date within each user_id
  rearrangedRows.sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;

    let dateA = new Date(a[2]);
    let dateB = new Date(b[2]);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    return 0;
  });

  rearrangedRows.unshift(headers);

  // Fetch and format the absence reports
  if (absenceReports.length > 0 && path != "absence") {
  const absenceHeaders = Object.keys(absenceReports[0]);

  // Set absence headers priority
  const absencePriorityHeaders = [
    "user_name",
    "user_id",
    "date",
    "week",
    "start_time",
    "stop_time",
    "absence_type",
    "break_time",
    "comp_30",
    "comp_50",
    "comp_70",
    "comp_100",
    "has_allowance",
    "has_travel_expences",
    "out_call",
  ];
  
  // Sort headers with priority
  absenceHeaders.sort((a, b) => {
    if (absencePriorityHeaders.includes(a) && absencePriorityHeaders.includes(b)) {
      return absencePriorityHeaders.indexOf(a) - absencePriorityHeaders.indexOf(b);
    }
    if (absencePriorityHeaders.includes(a)) {
      return -1;
    }
    if (absencePriorityHeaders.includes(b)) {
      return 1;
    }
    return 0;
  });

  const rearrangedAbsenceRows = absenceReports.map((row) => {
    return absenceHeaders.map((h) => row[h]);
  });

  // Sort rows by user_id and then date within each user_id
  rearrangedAbsenceRows.sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;

    let dateA = new Date(a[2]);
    let dateB = new Date(b[2]);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    return 0;
  });

  // Add a separator and a title for absence reports
  rearrangedRows.push(new Array(headers.length).fill('')); // Adding a blank row
  rearrangedRows.push(['Frånvaro']); // Adding the title
  rearrangedRows.push(new Array(headers.length).fill('')); // Adding another blank row

  // Add the headers for the absence reports
  rearrangedRows.push(absenceHeaders);

  // Add the absence reports data
  rearrangedAbsenceRows.forEach((row) => rearrangedRows.push(row));
}
  /* Create the workbook object */
  const workbook = XLSX.utils.book_new();

  /* Add the sheet with the data */
  const sheet = XLSX.utils.aoa_to_sheet(rearrangedRows);
  XLSX.utils.book_append_sheet(workbook, sheet, "Sheet 1");

  /* Generate the Excel file and download it */
  XLSX.writeFile(workbook, "exported-data.xlsx");
  setIsLoadingExcel(false);
};

export default exportToExcel;
