const calculateWorkHours = (startTime, stopTime, breakTime) => {
  const startHour = startTime.split(":")[0];
  const startMinute = startTime.split(":")[1];
  const finishHour = stopTime.split(":")[0];
  const finishMinute = stopTime.split(":")[1];
  const startHourInMinutes = parseInt(startHour) * 60;
  const finishHourInMinutes = parseInt(finishHour) * 60;
  const totalStartMinutes = startHourInMinutes + parseInt(startMinute);
  const totalFinishMinutes = finishHourInMinutes + parseInt(finishMinute);
  const totalMinutes = totalFinishMinutes - totalStartMinutes;
  if (!breakTime || breakTime === 0) {
    breakTime = 60;
  }
  const totalHours = breakTime
    ? (totalMinutes - breakTime) / 60
    : totalMinutes / 60;
  const roundedTotalHours = Math.round(totalHours * 100) / 100;
  return roundedTotalHours;
};

export default calculateWorkHours;
